import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FormSelect } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

export default function RejectOffer({ offerId }) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selected, setSelected] = useState();
  const [answers, setAnswers] = useState([]);
  const getData = () => {
    axios.get("/offer/data").then((response) => {
      setAnswers(response.data.reason);
      setSelected(response.data.reason[0].id);
    });
  };
  const reject = () => {
    axios
      .post(`/offer/${offerId}/reject`, {
        reason: selected,
      })
      .then((response) => {
        navigate("/offers");
      });
  };

  return (
    <>
      <Button variant="danger" className="fz-12 m-1" onClick={handleShow}>
        {t("Rejects.reject")}
      </Button>

      <Modal show={show} onHide={handleClose} onEnter={getData}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Rejects.reason")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormSelect
            className={"my-5"}
            onChange={(e) => setSelected(e.target.value)}
            value={selected}
          >
            {answers?.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </FormSelect>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={reject}>
            {t("Rejects.reject")}
          </Button>
          <Button variant="outline-dark" onClick={handleClose}>
            {t("Orders.cancel_btn")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
