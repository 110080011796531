import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import "./scss/index.scss";
import LoginLayout from "./layouts/loginLayout";
import MainLayout from "./layouts/mainLayout";
import Login from "./pages/login";
import LoginReset from "./pages/loginReset";
import LoginNewPassword from "./pages/loginNewPassword";
import LoginMailSent from "./pages/loginMailSent";
import PasswordChanged from "./pages/loginPasswordChanged";
import MarketingList from "./pages/marketingList";
import Product from "./pages/product";
import axios from "axios";
import {useDispatch} from "react-redux";
import Orders from "./pages/orders";
import OrderDetails from "./pages/orderDetails";
import Offers from "./pages/offers";
import {getCartCount} from "./redux/cartSlice";
import ClientProfile from "./pages/clientProfile";
import Cart from "./pages/cart";
import {getUserData} from "./redux/userSlice";
import ErrorLayout from "./layouts/ErrorLayout";
import UrlNotFound from "./pages/urlNotFound";
import ServerError from "./pages/serverError";
import useRequestSettings from "./helpers/requestsSettings";
import OfferDetails from "./pages/offerDetails";
import Home from "./pages/home";
import Reclamations from "./pages/reclamations";
import ReclamationDetails from "./pages/reclamationDetails";
import NewReclamation from "./pages/newReclamation";
import SetPassword from "./pages/setPassword";
import Invoices from "./pages/invoices";
import Scanner from "./pages/scanner";
import Test from "./pages/test";
import {useTranslation} from "react-i18next";

function App() {
    const {t} = useTranslation(); // DON'T REMOVE. Without this app won't rerender on langChange.
    const dispatch = useDispatch()
    axios.defaults.baseURL = 'https://a.api.b2bdecora.pl/api';

    const token = localStorage.getItem("token")

    function RequireAuth({children}) {

        let location = useLocation();

        if (!token) {
            return <Navigate to="/login" state={{from: location}} replace/>;
        }

        return children;
    }
    useRequestSettings()

    useEffect(() => {

        if (token) {
            dispatch(getCartCount());
            dispatch(getUserData());
        }
    }, []);

    return (<div className="App">
        <Routes>
            <Route element={<LoginLayout/>}>
                {/*<Route path="/" element={<Login/>}/>*/}
                <Route path="/login" element={<Login/>}/>
                <Route path="/login-reset/:token" element={<SetPassword/>}/>
                <Route path="/login-reset" element={<LoginReset/>}/>
                <Route path="/login-mail-sent" element={<LoginMailSent/>}/>
                <Route path="/login-password-changed" element={<PasswordChanged/>}/>
                <Route path="/login-new-password" element={
                    <RequireAuth>

                        <LoginNewPassword/>
                    </RequireAuth>}/>


            </Route>

            <Route path="/home" element={
                <RequireAuth>
                    <Home/>
                </RequireAuth>}
            />
            <Route path="/" element={
                <RequireAuth>
                    <Home/>
                </RequireAuth>}
            />
            <Route element={<MainLayout/>}>

                <Route path="/marketing-list" element={
                    <RequireAuth>
                        <MarketingList/>
                    </RequireAuth>
                }/>
                <Route path="/test" element={
                    <RequireAuth>
                        <Test/>
                    </RequireAuth>
                }/>
                <Route path="/test/:category" element={
                    <RequireAuth>
                        <Test/>
                    </RequireAuth>
                }/>
                <Route path="/marketing-list/:category" element={
                    <RequireAuth>
                        <MarketingList/>
                    </RequireAuth>
                }/>
                <Route path="/marketing-list/:category/:subcategory" element={
                    <RequireAuth>
                        <MarketingList/>
                    </RequireAuth>
                }/>
                <Route path="/products-list" element={
                    <RequireAuth>
                        <MarketingList/>
                    </RequireAuth>}/>
                <Route path="/product/:productId" element={
                    <RequireAuth>
                        <Product/>
                    </RequireAuth>}/>
                <Route path="/shoppingCart" element={
                    <RequireAuth>
                        <Cart/>
                    </RequireAuth>}/>
                <Route path="/orders" element={
                    <RequireAuth>
                        <Orders/>
                    </RequireAuth>}/>

                <Route path="/orders/:orderNumber" element={
                    <RequireAuth>
                        <OrderDetails/>
                    </RequireAuth>}
                />
                <Route path="/orders/:orderNumber/:orderId" element={
                    <RequireAuth>
                        <OrderDetails/>
                    </RequireAuth>}
                />
                <Route path="/offers/:offerId" element={
                    <RequireAuth>
                        <OfferDetails/>
                    </RequireAuth>}
                />
                <Route path="/offers" element={
                    <RequireAuth>
                        <Offers/>
                    </RequireAuth>}
                />
                <Route path="/client-profile" element={
                    <RequireAuth>
                        <ClientProfile/>
                    </RequireAuth>}
                />

                <Route path="/complaints/:reclamationId" element={
                    <RequireAuth>
                        <ReclamationDetails/>
                    </RequireAuth>}/>
                <Route path="/complaints" element={
                    <RequireAuth>
                        <Reclamations/>
                    </RequireAuth>}/>
                <Route path="/new-complaint/:orderId" element={
                    <RequireAuth>
                        <NewReclamation/>
                    </RequireAuth>}/>
                <Route path="/new-complaint" element={
                    <RequireAuth>
                        <NewReclamation/>
                    </RequireAuth>}/>
                <Route path="/invoices" element={
                    <RequireAuth>
                        <Invoices/>
                    </RequireAuth>}
                />
                <Route path="/scanner" element={
                    <RequireAuth>
                        <Scanner/>
                    </RequireAuth>}
                />
                </Route>

            <Route element={<ErrorLayout/>}>
                <Route path={'/*'}
                       element={
                           <RequireAuth>
                               <UrlNotFound/>
                           </RequireAuth>}
                />
                <Route path={'/500'}
                       element={
                           <RequireAuth>
                               <ServerError/>
                           </RequireAuth>}
                />

            </Route>
        </Routes>
    </div>);
}

export default App;
