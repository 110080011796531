import {React, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {removeProductFromCart} from "../../redux/cartSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";


const RemoveModal = ({items, setTouched, setSelected}) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState('');
const { t } = useTranslation();  

  const dispatch = useDispatch()
  const handleClose = () => setShow(false);
  const removeItem = () => {
    setLoading(true)
    items.forEach(item => {
      dispatch(removeProductFromCart({
        productSapIndex: item?.product.sapIndex,
        uniqueKey: item.uniqueKey
      })).then(() => {
        handleClose()
        setLoading(false)
        setTouched(true)
        setSelected([]);
      })
    })
  }
  const handleShow = () => {
    setAlert(t("Others.sure_checked"));
    setShow(true);
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{alert}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          {loading ? (
            <div className={"d-flex justify-content-center w-100 "}>
              <Spinner animation={"border"} />
            </div>
          ) : (
            <>
              <Button variant="danger" onClick={removeItem}>
                {t("Orders.delete_btn")}
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                {t("Orders.cancel_btn")}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Button
        className={items.length != 0 ? "" : " disabled"}
        onClick={items.length != 0 ? () => handleShow() : null}
      >
        {t("Orders.delete_checked")}
      </Button>
    </>
  );
}

export default RemoveModal;