import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import handleCartOffers from "../helpers/handleCartOffers";
import i18next from "i18next";


export const fetchCart = createAsyncThunk(
    "cart/fetchCart", async (_, thunkAPI) => {

        const response = await axios.get("/cart");


        return await response.data
    }
)
export const getCartCount = createAsyncThunk(
    "cart/getCartCount", async (_, thunkAPI) => {
        const response = await axios.get("/cart/count");
        return await response.data
    }
)

export const updateCart = createAsyncThunk(
    "cart/updateCart", async (products = Array) => {
        const response = await axios.put("/cart", {
            products: products
        })
        return await response.data
    }
)
export const removeProductFromCart = createAsyncThunk(
    "cart/removeProduct", async (product, thunkAPI) => {

        const data = {
            products: [
                {
                    ...product
                }
            ]
        }
        const response = await axios.delete("/cart", {data: JSON.stringify(data)});
        await thunkAPI.dispatch(getCartCount())
        const ret = {
            data: await response.data,
            product: product
        }
        return ret
    }
)
export const removeOfferFromCart = createAsyncThunk("cart/removeOffer", async (offerId, thunkAPI)=>{

   const response = await axios.delete(`/offer/${offerId}/cart`);
   await thunkAPI.dispatch(getCartCount())

    return {
       id: offerId, response: await response
    }
})
export const addToCart = createAsyncThunk(
    "cart/addToCart", async (product, thunkAPI) => {

        console.log(product.selectedUnit)

        const data = {
            productSapIndex: product.itemData.sapIndex,
            unit: product.unit,
            selectUnit: product.selectedUnit,
            quantity: product.quantity
        }
        const response = await axios.post("/cart", JSON.stringify(data))
        await thunkAPI.dispatch(getCartCount())
        return await response.data

    }
)
export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: [],
        offers: [],
        loadingCart: false,
        adding: false,
        showCartAlert: false,
        cartAlertMessage: "",
        cartCount: 0,

    },
    reducers: {

        hideCartAlert(state) {
            state.showCartAlert = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCartCount.fulfilled, (state, {payload}) => {
            state.cartCount = payload.totalCount
        });
        builder.addCase(fetchCart.pending, (state) => {

            state.loadingCart = true;
        });
        builder.addCase(
            fetchCart.fulfilled, (state, {payload}) => {

                state.offers = handleCartOffers(payload.items[0].cartProducts)
                state.cart = payload.items[0].cartProducts.filter(element => !element.isFromOffer);
                state.loadingCart = false;
            });
        builder.addCase(
            fetchCart.rejected, (state, action) => {
                state.loadingCart = false;
                // state.error = action.error.message;
            });
        builder.addCase(
            removeProductFromCart.fulfilled, (state, {payload}) => {

                state.cart = state.cart?.filter(element => element.uniqueKey !== payload.product.uniqueKey)

            });
        builder.addCase(
            addToCart.pending, (state) => {

                state.adding = true;

            });
        builder.addCase(
            addToCart.fulfilled, (state, {payload}) => {

const {t} = i18next
                state.showCartAlert = true;
                state.cartAlertMessage = t("ProductPage.added")

            });
        builder.addCase(
            addToCart.rejected, (state, action) => {
                state.adding = false;
                // state.error = action.error.message;
            });
        builder.addCase(
            removeOfferFromCart.fulfilled, (state,{payload})=>{
                state.offers = state.offers.filter(offer => offer[0].offerId !== payload.id)
            }
        )


    }
})

// Action creators are generated for each case reducer function
export const {hideCartAlert, } = cartSlice.actions

export default cartSlice.reducer