import {createSlice} from "@reduxjs/toolkit";

export const marketingListSlice = createSlice({
    name: "toast",
    initialState: {
        view: true,
    },
    reducers: {
        toggleView: (state, {payload}) => {
            state.view = payload
        }
    }
})

export const {toggleView} = marketingListSlice.actions

export default marketingListSlice.reducer