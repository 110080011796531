import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useState} from "react";
import {ModalBody, ModalHeader} from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CommentPreview = ({comment})=>{
const { t } = useTranslation();  
    const [show, setShow] = useState(false);
    return (
      <>
        <Modal show={show} onHide={() => setShow(false)}>
          <ModalHeader closeButton={true}>{t("Pop.comment")}</ModalHeader>
          <ModalBody>
            <p>{comment ?? ""}</p>
          </ModalBody>
        </Modal>
        <Button
          onClick={() => setShow(true)}
          className={"p-1"}
          variant={"link"}
          disabled={!comment}
        >
          <i className="bi bi-chat-dots" style={{ fontSize: "24px" }}></i>
        </Button>
      </>
    );
}
export default CommentPreview