import Button from "react-bootstrap/Button";
import React, {useEffect, useRef, useState} from "react";
import {FormControl} from "react-bootstrap";
import debounce from "lodash/debounce";
import {useSearchParams} from "react-router-dom";



const TableSearch = ({ filterKey}) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const val = searchParams.get(`filters[${filterKey}][]`) ?? ""
    const [toggle, setToggle] = useState(val !== "");

    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && val === "") {
                    setToggle(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const ref = useRef(null)
    useOutsideAlerter(ref)
    const debounceFetcher = React.useMemo(() => {
        const handleSearchQuery = (event) => {
            const value = event.target.value

            if (value !== "") {
                setSearchParams(state => {
                    state.set(`filters[${filterKey}][]`, value)
                    state.set("page", 1)
                    return state
                })

            } else {
                setSearchParams(state => {
                    state.delete(`filters[${filterKey}][]`)
                    state.set("page", 1)
                    return state
                })


                setToggle(false)
            }

        };

        return debounce(handleSearchQuery, 800);
    }, []);



    useEffect(() => {
        if (toggle) {
            ref.current.focus()
        }
    }, [toggle])
    return (
        <div className="table-sort" >

                {toggle ?
                    <FormControl
                        ref={ref}
                        defaultValue={val}
                        onChange={(event) => {
                            debounceFetcher(event)
                        }}
                        size={"sm"}
                        type={"text"}
                    /> :
                    <Button variant="link" className={"p-0"} onClick={() => setToggle(!toggle)}>

                    <img src="/img/lupa.svg" alt=""/>
                    </Button>

                }


        </div>
    )
}
export default TableSearch