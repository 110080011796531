import axios from "axios";
import {useNavigate} from "react-router-dom";
import i18n from 'i18next';


export default function useRequestSettings() {
    const navigate = useNavigate()


    axios.interceptors.request.use(
        async config => {

            config.params = {
                ...config.params,
                lang: i18n.language
            }
            const token = localStorage.getItem("token")
            if (token) {
                config.headers = {
                    ...config.headers,
                    "Authorization": `Bearer ${token}`
                }
            }

            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );
    let failedRequests = []
    let refreshing = false
    const processQueue = (error, token = null) => {
        failedRequests.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedRequests = [];
    };
    axios.interceptors.response.use((response) => {
        return response
    }, function (error) {

        let originalRequest = error.config;


        if (error?.response?.status === 401 && !originalRequest._retry && originalRequest.url !== "/user/login") {
            if (refreshing) {
                return new Promise(function(resolve, reject) {
                    failedRequests.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }
            originalRequest._retry = true;
            const rToken = localStorage.getItem("refreshToken");

                refreshing = true

            return new Promise(function(resolve) {
                 axios
                    .post("/user/refreshToken",
                        JSON.stringify({refreshToken: rToken}),
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    ).then(response => {
                        localStorage.setItem("refreshToken", response.data.refreshToken);
                        localStorage.setItem("token", response.data.token);
                        originalRequest.headers = JSON.parse(JSON.stringify({
                            'Authorization': `Bearer ${response.data.token}`,
                            ...originalRequest.headers
                        } || {}))
                        processQueue(null, response.data.token);
                        resolve(axios(originalRequest));
                    }).then(()=>{
                        refreshing = false

                    })
            })



        }
        if (error?.response?.status === 500 && !originalRequest._retry) {
            navigate("/500")
        }
        return Promise.reject(error);

    });

}