import React from "react";
import {Row, Col} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


// import { Row, Col } from "react-bootstrap";

const Thanks = () => {
    const navigate = useNavigate()
    const userData = useSelector(state => state.user.data)
const { t } = useTranslation();  
    


    return (
      <>
        <Row className="second-gray-bg vh-100 thanks-container justify-content-center">
          <Col className="thanks-text-wrapper text-center">
            <div className="mb-30">
              <img src="./img/done-green-bg.svg" alt="" />
            </div>
            <h2 className="mb-30">
              {userData.debtsDue === "0"
                ? t("Others.thanks")
                : t("Others.in_order")}
            </h2>

            <div>
              <Button
                variant="dark"
                className="w-279"
                onClick={() => navigate("/marketing-list/1")}
              >
                {t("Others.back_list")}
              </Button>
            </div>
          </Col>
        </Row>
      </>
    );
};
export default Thanks;
