import Button from "react-bootstrap/Button";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { FormLabel, FormSelect, Spinner } from "react-bootstrap";
import AddProduct from "./addProduct";
import { setOrderNumber } from "../../redux/reclamationSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Scan } from "../../svg/Scan.svg";
import ScanditScanner from "../snaditScanner/scanditScanner";
import { useTranslation } from "react-i18next";

export default function AddByScan({
  getProducts,
  addProductToReclamation,
  orderProducts,
}) {
  const { orderNumber, products } = useSelector((state) => state.reclamation);
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [show, setShow] = useState(false);
  const [productEan, setProductEan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [product, setProduct] = useState();
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const hitsMap = new Map();
  // hitsMap.set(DecodeHintType.POSSIBLE_FORMATS, [4] )

  const handleEan = (ean) => {
    if (orderNumber) {
      const p = products.find((item) => parseInt(item.ean) === parseInt(ean));

      if (p) {
        setLoading(false);
        setProduct(p);
      } else {
        setLoading(false);
        setError("Nie znaleziono produktu.");
      }
    } else {
      axios
        .all([
          axios
            .get(
              `/complaint/order?filters[complaintProductEan][]=${ean}&limit=100&page=1`
            )
            .then((r) => {
              if (r.data.items?.length) {
                setOrders(r.data.items);
              } else {
                setError(t("Others.not_found"));
              }
            }),
          axios
            .get(`/product?filters[productEan][]=${ean}&limit=1`)
            .then((r) => {
              setProduct(r.data.items[0]);
            }),
        ])
        .then(() => {
          setLoading(false);
        });
    }
    setProductEan(ean);
  };

  function orderSelect(event) {
    setOrderId(event.target.value);
    dispatch(setOrderNumber(event.target.value));
    getProducts(event.target.value);
  }

  function reset() {
    setShow(false);
    setProductEan(null);
    setProduct(null);
  }

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        <Scan /> {t("Others.scan")}
      </Button>

      <Modal
        show={show}
        size={"lg"}
        onHide={handleClose}
        onEntering={() => {
          if (
            !orderNumber ||
            !products.some((item) => parseInt(item.ean) === productEan)
          ) {
            setProduct(null);
            setProductEan(null);
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Others.scaner")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div
              className={
                "d-flex w-100  p-5 justify-content-center align-items-center"
              }
            >
              <Spinner animation={"border"} />
            </div>
          ) : error ? (
            <>{error}</>
          ) : productEan ? (
            <>
              <div className={"d-flex flex-column"}>
                <span className={"my-2"}>
                  {t("Pop.order_number")}: {orderNumber}
                </span>
                <span>{product?.name}</span>
                <span className={"my-2"}>EAN: {product?.ean}</span>
              </div>
              {orderNumber ? null : (
                <>
                  <FormLabel>{t("Reclamations.choose")}</FormLabel>
                  <FormSelect onChange={orderSelect} value={orderId}>
                    <option value=""></option>
                    {orders?.map((order, i) => (
                      <option value={order.sapOrderNumber} key={i}>
                        {order.sapOrderNumber}
                      </option>
                    ))}
                  </FormSelect>
                </>
              )}
            </>
          ) : (
            <ScanditScanner onResult={handleEan} />
          )}
        </Modal.Body>
        <Modal.Footer>
          {productEan || error ? (
            <Button
              onClick={() => {
                setProduct(null);
                setProductEan(null);
                setError(null);
                handleClose();
              }}
              variant={"secondary"}
            >
              {t("Others.scan_again")}
            </Button>
          ) : null}

          {product && orderNumber ? (
            <AddProduct
              orderProducts={orderProducts}
              defaultProduct={product}
              onAdd={addProductToReclamation}
              disabled={product?.quantity === 0}
              isFromScan={true}
              backToScan={reset}
              buttonText={t("Reclamations.add")}
            />
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
}
