import {React, useState} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function FloatCounter({className = "", quantity, setQuantity, max = null, disabled = false }) {

    const handleChange = (e) => {

        let val = parseFloat(e.target.value)

        if (!(max === null || (max !== null && val <= max))) {
            return;
        }
        setQuantity(val)


    }
    //increase counter
    const increase = () => {
        if(max === null || (max !== null && quantity + 1 <= max)){
            setQuantity((count) => count + 1);
        } else {
            return
        }
    };

    //decrease counter
    const decrease = () => {
        if (quantity >= 1) {
            setQuantity((count) => count - 1);
        }
    };

    return (
        <Form.Group className={`counter ${className}`}>
            <Button variant="light" onClick={decrease}
                    disabled={disabled}
                    className="border-0">
                <img src="/img/minus.svg" alt=""/>
            </Button>{" "}
            <Form.Control
                value={quantity}
                size="sm"
                className="text-center"
                type={"number"}
                onChange={handleChange}
                disabled={disabled}
            />
            <Button variant="light"
                    disabled={disabled}
                    onClick={increase} className="border-0">
                <img src="/img/plus.svg" alt=""/>
            </Button>{" "}
        </Form.Group>
    );
}
