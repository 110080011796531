export const languages = [
    {
        lng: 'pl',
        img: 'poland.webp'
    },
    {
        lng: 'en',
        img: 'united-kingdom.webp'
    },
    {
        lng: 'ru',
        img: 'russia.png'
    },
    {
        lng: 'cz',
        img: 'cz.png'
    },
    {
        lng: 'sv',
        img: 'sv.png'
    },
    {
        lng: 'es',
        img: 'es.png'
    },
    {
        lng: 'sk',
        img: 'sk.png'
    },
    {
        lng: 'nl',
        img: 'nl.png'
    },
    {
        lng: 'de',
        img: 'de.png'
    },
    {
        lng: 'fr',
        img: 'fr.png'
    },

]