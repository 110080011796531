import {FormControl} from "react-bootstrap";
import {useState} from "react";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";

export default function ProductOrderNumber({product, setItems, index}){
    const { t } = useTranslation();
    const [showInput, setShowInput] = useState(false);
    function handleInput(event){
        const {value}= event.target

        setItems(state => {
            const newState = state.map((item, i) => {
                if(i === index){
                    return{
                        ...item,
                        customerOrderNumber: value
                    }
                }else {
                    return item
                }
            })
            return newState
        })

    }
    function handleToggle(){
        setShowInput(state => {
            if(state){
                handleInput({target: {value: null}})
                return false
            }else {
                return true
            }
        })
    }


    return<>
        <Button
            onClick={handleToggle}
            variant={'link'}
            className={'text-danger'}
        >
            {t("Cashbox.enter_own_number")}
        </Button>
        {
         showInput?
        <FormControl
            maxLength={35}
            placeholder={t("Pop.order_number")}
            value={product.customerOrderNumber}
            className={'p-1 border-black-80'}
            type={'text'} onChange={handleInput}/> : null
        }
    </>
}