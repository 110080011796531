import {createSlice} from "@reduxjs/toolkit";

export const reclamationSlice= createSlice(
    {
        name: "reclamation",
        initialState: {
            products: [],
            reclamationProducts: [],
            orderNumber: null,
            options: []

        },
        reducers:{
            setProducts: (state, {payload})=>{
                state.products = payload
            },
            setReclamationProducts: (state, {payload})=>{
                state.reclamationProducts = payload
            },
            addReclamationProduct: (state, {payload})=>{
                state.reclamationProducts = [...state.reclamationProducts, payload]
            },
            setOrderNumber :(state, {payload})=>{
                state.orderNumber = payload
            }, setOptions: (state , {payload})=>{
                state.options  = payload
            }
        }
    }
)
export const { setOptions,setOrderNumber, setProducts, setReclamationProducts,addReclamationProduct } = reclamationSlice.actions
export default reclamationSlice.reducer