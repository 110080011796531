import TabelProduct from "./product";
import axios from "axios";
import {React, useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";


const DedicatedItem = ({item, acesories})=>{
    const [isActive, setActive] = useState(false);


    const toggleClass = () => {
        setActive(!isActive);
    };
    return (
        <>
            <TabelProduct item={item} isActive={isActive} toggleClass={toggleClass} acesories={acesories} className={"medium-gray-bg"}/>

            {
                isActive ?
                    <TableDedicated itemId={item.id}   acesories={false} />
                    : null
            }
        </>

    );
}
const TableDedicated = ({itemId, acesories =true }) => {
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const getProducts = () => {
        setLoading(true)
        axios.get("/product/dedicated/" + itemId).then((response) => {

            setProducts(response.data.items)
            setLoading(false)
        })
    }
    useEffect(() => {
        getProducts()
    }, [])
    return (
        loading ?

            <tr>
                <td colSpan={5}>
                    <Spinner animation={"border"}/>
                </td>
            </tr>
            :
            products.sort((a, b) => {
                return  b.category.id - a.category.id
            }).map((item,index)=>(
                <DedicatedItem item={item} key={index} acesories={acesories}  />
            ))

    )
}
export default TableDedicated