import HeaderBar from "../components/headerBar/headerBar";
import React, {useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import ShoppingCart from "../components/cart/shoppingCart";
import CashBox from "../components/cart/cashBox";
import Thanks from "../components/cart/thanks";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useTranslation } from "react-i18next";


const Cart = () => {
const { t } = useTranslation();  

    const [scroll, setScroll] = useState(null)
    const [cashRegister, setCashRegister] = useState(null)
    const [showThanks, setShowThanks] = useState(false)
    const [minLogistic, setMinLogistic] = useState({shippingToOwnAddressPrice: 0, freeDeliveryFromToOwnAddress: 0})
    const getMinLogistic = () => {
        axios.get("/minima-logistic/details").then((response) => {
            setMinLogistic(response.data.minimaLogistic)
        })
    }
    const scrollTop = () => {
        scroll.scrollTop = 0;
    }
    useEffect(getMinLogistic, [])
    return !showThanks ? (
      <>
        <HeaderBar
          header={cashRegister ? t("Pop.cashbox") : t("Pop.cart")}
          className="ps-34"
        >
          {cashRegister ? (
            <Button onClick={() => setCashRegister(false)}>
              {t("Pop.backToCart")}
            </Button>
          ) : null}
        </HeaderBar>
        <Row>
          <Col className="gray-bg custom-scroll content-container with-header-bar">
            <PerfectScrollbar containerRef={(x) => setScroll(x)}>
              {!cashRegister ? (
                <ShoppingCart
                  setCashRegister={setCashRegister}
                  minLogistic={minLogistic}
                  getMinLogistic={getMinLogistic}
                />
              ) : (
                <CashBox
                  setShowThanks={setShowThanks}
                  cashRegister={cashRegister}
                  minLogistic={minLogistic}
                  scrollTop={scrollTop}
                />
              )}
            </PerfectScrollbar>
          </Col>
        </Row>
      </>
    ) : (
      <Thanks />
    );
}
export default Cart