import React, {useCallback, useEffect, useRef, useState} from "react";
import {Alert, Spinner,} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import "react-perfect-scrollbar/dist/css/styles.css";
import {useDispatch, useSelector} from "react-redux";
import CustomTableRow from "../customTable/customTableRow";
import {fetchCart, updateCart,} from "../../redux/cartSlice";
import SubTable from "../customTable/subTable";
import AccesoriessTabs from "../product/accesoriessTabs";
import CustomTable from "../table/customTable";
import axios from "axios";
import useSum from "./useSum";
import Offer from "./offer";
import RemoveModal from "../modal/removeModal";
import {useTranslation} from "react-i18next";
import AddToCart from "../product/btnAddToCart";
import Delete from "./delete";
import round from "../../helpers/round";

const ShoppingCart = ({ setCashRegister, minLogistic, getMinLogistic }) => {
  const { t } = useTranslation();

  // const lastColWidth = "270px";
  const columns = [
    {
      title: t("ProductSpec.name"),
      dataKey: "product",
      width: "calc((100% - 270px) / 5)",
      render: (value) => <>{value.name}</>,
    },
    {
      title: t("Cart.number"),
      dataKey: "quantity",
      width: "calc((100% - 270px) / 5)",
    },
    {
      title: t("Cart.unit"),
      dataKey: "transUnit",
      width: "calc((100% - 270px) / 5)",
    },
    {
      title: t("Cart.order_number"),
      dataKey: "orderNumber",
      width: "calc((100% - 270px) / 5)",
    },
    {
      title: t("Cart.order_date"),
      dataKey: "orderDate",
      width: "calc((100% - 270px) / 5)",
    },
    {
      dataKey: "product",
      // width: "20%",
      render: (value, item) => {
       return (
            <>
              <div className="d-flex gap align-items-center mt-3 mt-lg-0">
                <AddToCart
                    unit={item.unit}
                    quantity={item.quantity}
                    item={value}
                    netto={""}
                    selectedUnit={item.unit}
                    buttonText={t("Cart.add")}
                    setQuantity={(v) => console.log(v)}
                />
                <Delete item={item} getData={getNotDelivered} />
              </div>
            </>
        )
      },
      width: "270px",
    },
  ];
  const [dedicatedProducts, setDedicatedProducts] = useState([]);

  const useResize = (myRef) => {
    const [width, setWidth] = useState(0);
    const [width2, setWidth2] = useState(0);
    const [width3, setWidth3] = useState(0);
    const [width4, setWidth4] = useState(0);

    const handleResize = useCallback(() => {
      setWidth(myRef?.current?.offsetWidth);
      setWidth2(myRef?.current?.offsetWidth);
      setWidth3(myRef?.current?.offsetWidth);
      setWidth4(myRef?.current?.offsetWidth);
    }, [myRef]);

    useEffect(() => {
      handleResize();
      window.addEventListener("load", handleResize);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("load", handleResize);
        window.removeEventListener("resize", handleResize);
      };
    }, [myRef, handleResize, cart]);

    return { width, width2, width3, width4 };
  };
  const {cart, offers,  loadingCart} = useSelector((state) => state.cart);

  const [showOrderAvailable, setShowOrderAvailable] = useState(false)

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [notDelivered, setNotDelivered] = useState(null);
  const [activeKeys, setActiveKeys] = useState(["0", "1", "2", "3"]);
  const [accordionShow, setAccordionShow] = useState(true);
  const accordionCollapse = document.querySelectorAll(".accordion-collapse");

  const getNotDelivered = () => {
    axios.get("/order/not-delivery").then((response) => {
      setNotDelivered(response.data);
    });
  };

  const getDedicatedProducts = async () => {
    let products = dedicatedProducts;
    let i = 0;
    do {
      await axios
        .get(`/product/dedicated/${cart[i].product.id}`)
        .then((response) => {
          products = response.data.items;
          setDedicatedProducts(response.data.items);
        });
      i++;
    } while (products.length == 0 && i < cart.length);
  };

  useEffect(() => {
    dispatch(fetchCart()).then(res =>{
      const  products =res.payload.items[0].cartProducts
      const someAvailable = products.some((item)=> item.availableNow === true)
      const someNotAvailable = products.some((item)=> item.availableNow === false)

      setShowOrderAvailable( !!(someAvailable && someNotAvailable))
    });

    getNotDelivered();
  }, []);
  const [valuesList, setValuesList] = useState([]);
  useEffect(() => {
    if (!cart) return;

    const list = [];
    cart?.forEach((element) => {
      const {grossWeight, volume}= element.product.technicalParameters.logisticData
      list.push({
        uniqueKey: element.uniqueKey,
        sapIndex: element.product.sapIndex,
        val: parseFloat(element.productPrice?.mainUnitPriceNet) * element.quantity,
        weight: round(grossWeight * element.quantity),
        volume: round((volume * element.quantity)/ 1000000)
      });
    });
    offers?.forEach((element) => {
      element.forEach((item) => {
        const value = item.productPrice.valueNet;
        const {grossWeight, volume}= item.product.technicalParameters.logisticData
        list.push({
          val: value,
          weight: round(grossWeight * item.quantity),
          volume: round((volume * item.quantity)/ 1000000)
        });
      });
    });

    setValuesList(list);

    if (cart?.some((item) => item.productPrice === null ) ) {
      setErrorMessage(cart[0].sapErrorMessage !== null ? cart[0].sapErrorMessage : t('Cart.cant') );
    } else {
      setErrorMessage('');
    }
  }, [cart]);

  const sumUp = useSum(valuesList, minLogistic);

  const componentRef = useRef();
  const componentRef2 = useRef();
  const componentRef3 = useRef();
  const componentRef4 = useRef();

  const { width } = useResize(componentRef);
  const { width2 } = useResize(componentRef2);
  const { width3 } = useResize(componentRef3);
  const { width4 } = useResize(componentRef4);

  const widthSum = width + width2 + width3 + width4 + 1;
  const subTableWidth = {
    width: widthSum,
  };
  const priceCounterWidth = width + width2;
  const subTableFirstCol = {
    width: priceCounterWidth,
  };
  const navigate = useNavigate();
  const [selected, setSelected] = useState([])
  const [selectedSum, setSelectedSum] = useState(0)
  const handleCheckbox = ({ target }, item, price) => {

    if (target.checked) {
      setSelectedSum(state => state + price)
      setSelected((state) => [...state, item]);
    } else {
      setSelectedSum(state => state - price)
      setSelected((state) => {
        return state.filter((element) => element.uniqueKey !== item.uniqueKey);
      });
    }
  };
  const [selectedOffers, setSelectedOffers] = useState([]);
  const handleOfferCheck = (event, offer, price) => {

    if (event.target.checked) {
      setSelectedSum(state => state + price)
      setSelectedOffers((state) => [...state, offer]);
    } else {
      setSelectedSum(state => state - price)
      setSelectedOffers((state) => {
        return state.filter(
          (element) => element[0].offerId !== offer[0].offerId
        );
      });
    }
  };
  const [touched, setTouched] = useState(false);
  const [touchedItems, setTouchedItems] = useState([]);
  const handleTouch = (editItem, quantity) => {
    const uniqueKey = editItem.uniqueKey;
    const items = cart;
    const item = items.find((item) => item.uniqueKey === uniqueKey);
    const sapIndex = editItem.product.sapIndex;
    const secondPart = items.find(
      (item) =>
        item.product.sapIndex === sapIndex && item.uniqueKey !== uniqueKey
    );

    if (item.quantity !== quantity) {
      setTouched(true);

      if (
        touchedItems.some((element) => element.productSapIndex === sapIndex)
      ) {
        setTouchedItems((state) =>
          state.map((elem) => {
            if (elem.productSapIndex === sapIndex) {
              return {
                ...elem,
                quantity: quantity + (secondPart?.quantity ?? 0),
              };
            }
            return elem;
          })
        );
      } else {
        setTouchedItems((state) => [
          ...state,
          {
            productSapIndex: sapIndex,
            quantity: quantity + (secondPart?.quantity ?? 0),
          },
        ]);
      }
    } else {
      setTouched(false);
      setTouchedItems((state) =>
        state.filter((item) => item.productSapIndex !== sapIndex)
      );
    }
  };
  const [ordering, setOrdering] = useState(false);
  const handleOrder = (products, offers) => {
    setOrdering(true);
    let uniqueSapIndexList;
    uniqueSapIndexList = [];

    products.forEach((item) => {
      const sapIndex = item.product.sapIndex;
      const isUnique = !uniqueSapIndexList.some(
        (element) => element === sapIndex
      );
      if (isUnique) {
        uniqueSapIndexList.push(sapIndex);
      }
    });

    const data = {
      products: uniqueSapIndexList.map((sapindex) => {
        let q = 0;
        let unit;
        let selectedUnit;
        products.forEach((element) => {
          if (sapindex === element.product.sapIndex) {
            q = q + parseFloat(element.quantity);
            unit = element.unit;
            selectedUnit = element.selectUnit;
          }
        });
        return {
          productSapIndex: sapindex,
          quantity: q,
          unit: unit,
          selectUnit: selectedUnit,
        };
      }),
    };

    axios.post("/cash-register/price", JSON.stringify(data)).then((res) => {
      //przesyłać tylko isFromOffer = false
      let cartSum = 0;
      res.data.items?.forEach((item) => {
        cartSum = cartSum + parseFloat(item.valueNet);
      });
      let offersSum = 0;
      offers.forEach((element) => {
        offersSum =
          offersSum +
          element.reduce(
            (subSum, item) => subSum + item?.productPrice?.valueNet ?? 0,
            0
          );
      });

      axios
        .post("/cart/credit/limit", {
          currency:
            res.data.items[0]?.currency ?? offers[0][0]?.productPrice?.currency,
          orderValue: cartSum + offersSum,
        })
        .then((response) => {
          setOrdering(false);

          setCashRegister({
            offers: offers.map((element) => {
              return element.map((item) => {
                const {grossWeight, volume} =item.product.technicalParameters.logisticData

                const weight = round(grossWeight * item.quantity)
                const orderVolume = round((volume * item.quantity)/ 1000000)
                return {
                  ...item,
                  ...item.productPrice,
                  weight,
                  orderVolume
                };
              });
            }),
            items: uniqueSapIndexList.map((sapindex) => {
              let q = 0;

              let productData;
              let productPrices;

              products.forEach((element) => {
                if (sapindex === element.product.sapIndex) {
                  q = q + parseFloat(element.quantity);
                  productData = { ...element };
                  productPrices = res.data.items.find(
                    (item) => item.productSapIndex === sapindex
                  );
                }
              });
              const {grossWeight, volume}= productData.product.technicalParameters.logisticData
              const weight = round(grossWeight * q)
              const orderVolume = round((volume * q)/ 1000000)
              return {
                ...productData,
                ...productPrices,
                quantity: q,
                weight,
                orderVolume
              };
            }),
            ...response.data,
          });
        });
    });
  };

  const currency = cart ? cart[0]?.productPrice?.currency : "";
  const taxRate = cart ? cart[0]?.productPrice?.percentTax : "";

  const [selectedBtn, setSelectedBtn] = useState("");
    const selectAllProducts = () => {
      
    const productsCheckbox = document.querySelectorAll(".form-check-input");
    let countCheckedProducts = 0;

    productsCheckbox.forEach((checkbox) => {
      if (checkbox.checked) {
        countCheckedProducts += 1;
        checkbox.checked = true;
      }
    });

    if (countCheckedProducts != productsCheckbox.length) {
      productsCheckbox.forEach((checkbox) => {
        checkbox.checked = true;
      });
      cart
        ?.filter((elem) => !elem.isFromOffer)
        ?.map((item, index) => {
          if (productsCheckbox[index].checked) {
            const found = selected.find(
              (element) => element.uniqueKey == item.uniqueKey
            );
            if (!found) {
              setSelected((state) => [...state, item]);
            }
          }
        });
    } else {
      productsCheckbox.forEach((checkbox) => {
        checkbox.checked = false;
      });
      setSelected([]);
    }
  };

  useEffect(() => {
    
    if (selected.length == cart.length) {
      setSelectedBtn(t("Cart.uncheck"));
    } else {
      setSelectedBtn(t("Cart.check"));
    }
    if (cart.length > 0 && dedicatedProducts.length == 0) {
      getDedicatedProducts();
    }
  }, [selected, cart]);

  const toggleOneItem = (event, key) => {
    const isExpanded = activeKeys.some((item) => item === key);

    if (!isExpanded) {
      setActiveKeys((state) => [...state, key]);
    } else {
      setActiveKeys((state) => {
        const newKeys = state.filter((element) => element !== key);
        if (newKeys.length === 0) {
          setAccordionShow(false);
        }
        return newKeys;
      });
    }
  };

  const toggleClass = () => {
    setAccordionShow(!accordionShow);

    if (!accordionShow) {
      setActiveKeys(["0", "1", "2", "3"]);
    } else {
      setActiveKeys([]);
    }

    accordionCollapse[0].classList.contains("collapse")
      ? accordionCollapse[0].classList.add("show")
      : accordionCollapse[0].classList.add("show");
  };

  return (
    <>
      {loadingCart ? (
        <Spinner animation={"border"} />
      ) : (
        <div className="main-content cart">
          {cart?.length || offers?.length ? (
            <>
              <div className="custom-table-checkbox ms-3 mb-2 pb-5">
                <Button
                  className={"my-2 me-2"}
                  onClick={() => selectAllProducts()}
                >
                  {selectedBtn}
                </Button>
                <RemoveModal
                  items={selected}
                  setTouched={setTouched}
                  setSelected={setSelected}
                />
              </div>
              <div className="custom-table with-subtable product">
                {cart
                  ?.filter((elem) => !elem.isFromOffer)
                  ?.map((item, index) => (
                    <CustomTableRow
                      key={index}
                      item={item}
                      innerRef={componentRef}
                      innerRef2={componentRef2}
                      innerRef3={componentRef3}
                      innerRef4={componentRef4}
                      onCheck={handleCheckbox}
                      selected={selected}
                      isCart={true}
                      setValuesList={setValuesList}
                      setTouched={setTouched}
                      handleTouch={handleTouch}
                      setSelected={setSelected}
                    />
                  ))}
                {offers?.map((item, index) => (
                  <Offer
                    offer={item}
                    onCheck={handleOfferCheck}
                    selected={selectedOffers}
                    setTouched={setTouched}
                    innerRef={componentRef}
                    innerRef2={componentRef2}
                    innerRef3={componentRef3}
                    innerRef4={componentRef4}
                  />
                ))}
              </div>
              <SubTable
                deliveryPrice={sumUp?.netShipping}
                forFreeDelivery={sumUp?.forFreeDelivery}
                orderNetTotal={sumUp.netSum}
                taxRate={taxRate}
                tax={sumUp.taxValue}
                orderValue={sumUp.grosSum}
                subTableWidth={subTableWidth}
                subTableFirstCol={subTableFirstCol}
                currency={currency}
                orderBlockIfNotMet={minLogistic.orderBlockIfNotMet}
                sumWeight={sumUp.sumWeight}
                sumVolume={sumUp.sumVolume}
              />
              <div className="d-flex justify-content-between">
                <div className="left-side-button  order-buttons">
                  <Button variant="dark" onClick={() => navigate(-1)}>
                    {t("Orders.continue")}
                  </Button>
                </div>
                <div className="ms-auto me-auto me-lg-0 cart-order-buttons">
                  <div
                    className=" d-flex gap order-buttons"
                    style={subTableWidth}
                  >
                    {errorMessage.length ? (
                      <Alert variant={"danger"} >{errorMessage}</Alert>
                    ) : touched ? (
                      <Button
                        onClick={() => {
                          if (touchedItems.length) {
                            setSelected([]);
                            setDedicatedProducts([]);
                            dispatch(updateCart(touchedItems)).then(() => {
                              dispatch(fetchCart()).then(() => {
                                setTouched(false);
                              });
                              getMinLogistic();
                            });
                          } else {
                            setSelected([]);
                            setDedicatedProducts([]);
                            dispatch(fetchCart()).then(() => {
                              getMinLogistic();
                              setTouched(false);
                            });
                          }
                        }}
                      >
                        {t("Cart.update")}
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="success"
                          onClick={() => handleOrder(cart, offers)}
                          disabled={ordering ||( minLogistic.orderBlockIfNotMet && 0 < sumUp?.forFreeDelivery)}
                        >
                          {ordering ? (
                            <Spinner animation={"border"} size={"sm"} />
                          ) : (
                            t("Orders.order_everything")
                          )}
                        </Button>
                        {selected.length || selectedOffers.length ? (
                          <Button
                            variant="outline-success"
                            onClick={() =>
                              handleOrder(selected, selectedOffers)
                            }
                            disabled={ordering || ( minLogistic.orderBlockIfNotMet && parseInt(minLogistic.freeDeliveryFromToOwnAddress) > selectedSum)}
                          >
                            {ordering ? (
                              <Spinner animation={"border"} size={"sm"} />
                            ) : (
                              t("Orders.order_selected")
                            )}
                          </Button>
                        ) : offers.length || !showOrderAvailable ? null : (
                          <Button
                            variant="outline-success"
                            disabled={ordering}
                            onClick={() =>
                              handleOrder(
                                cart?.filter((item) => item.availableNow),
                                offers
                              )
                            }
                          >
                            {ordering ? (
                              <Spinner animation={"border"} size={"sm"} />
                            ) : (
                              t("Orders.order_available")
                            )}
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  {/*{!allAvailableNow ? (*/}
                  {/*  <div className="text-center d-flex notify">*/}
                  {/*    <div>*/}
                  {/*      <Link>{t("Orders.notify")}</Link>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*) : null}*/}
                </div>
              </div>
            </>
          ) : (
            t("Orders.empty")
          )}
        </div>
      )}

      {notDelivered?.length ? (
        <div className="main-content with-header">
          <div>
            <h2>{t("Orders.undelivered")}</h2>
          </div>
          <CustomTable columns={columns} data={notDelivered} />
        </div>
      ) : null}

      {cart.length > 0 ? (
        <div className="main-content with-header mx-3 m-md-0">
          {dedicatedProducts.length > 0 ? (
            <AccesoriessTabs
              heading={
                <div>
                  <h2>{t("Orders.remember")}</h2>
                </div>
              }
              initItems={dedicatedProducts}
            />
          ) : null}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default ShoppingCart;
