import {Col} from "react-bootstrap";

function ShortSpecification({specType, specData}) {
    return (

        <Col md={6} className="short-specification-item">
            <div className="short-specification-type">{specType}:</div>
            <div className="short-specification-datas">{specData}</div>
        </Col>

    );
};
export default ShortSpecification;
