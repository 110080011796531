import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Badge, Col, Container, Row, Spinner} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Counter from "../components/product/counter";
import PriceInPill from "../components/product/priceInPill";
import AddToCart from "../components/product/btnAddToCart";
import Modal from "react-bootstrap/Modal";
import SwiperCom from "../components/swiperCom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "swiper/css";
import "swiper/css/navigation";
import axios from "axios";
import AccesoriessTabs from "../components/product/accesoriessTabs";
import SpecificationList from "../components/product/specificationList";
import VariantsSlider from "../components/product/variantsSlider";
import FullSpecification from "../components/product/fullSpecification";
import Price from "../components/price";
import getBasePrice from "../helpers/getBasePrice";
import priceAndUnit from "../helpers/priceAndUnit";
import checkAvailability from "../helpers/checkAvailability";
import CustomSelect from "../components/customSelect/customSelect";
import {currencyFormatter} from "../helpers/currencyTools";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

// import "./styles.css";

function BigModal(props) {
    const {t} = useTranslation();

    const {product, unit, quantity, onHide, modalData} = props;
    const navigate = useNavigate();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="product-added"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <img src="/img/big-done.svg" alt=""/>
                    {t("Orders.added")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="fz-14 fw-bold">{props.category}</div>
                        <h2 className="d-inline-block mb-10 mt-1">
                            {product?.name}
                            <Badge pill bg="dark" className="ms-3 fz-12 fw-bold">
                                {product?.color?.number}
                            </Badge>
                        </h2>
                        <div className="parameters">
                            <div>
                                <b>EAN:</b> {product?.ean}
                            </div>
                            <div>
                                <b>{t("ProductPage.brand")}:</b> {product?.brand?.name}
                            </div>
                            <div>
                                <b>{t("ProductPage.price")}:</b>{" "}
                                <Price priceList={product?.priceList}/>
                            </div>
                        </div>
                        <div className="sum mb-2">
                            <span>{t("ProductPage.sum")}: </span>
                            <span className="text-pill px-2">{`${(
                                modalData?.cartQuantity * product?.priceList?.minPrice
                            ).toFixed(2)} ${product?.priceList?.currency}`}</span>
                        </div>
                        <div>
              <span>
                {modalData?.cartQuantity} {modalData?.defaultUnit?.unitName}{" "}
                  {modalData?.defaultUnit?.unit !== modalData?.baseUnit?.unit ? (
                      <>
                          = {modalData?.baseQuantity} {modalData?.baseUnit?.unitName}
                      </>
                  ) : null}
              </span>
                        </div>
                        <div className="d-flex buttons">
                            <Button
                                variant="outline-dark"
                                className="px-4 me-3"
                                onClick={onHide}
                            >
                                {t("ProductPage.continue")}
                            </Button>
                            <Button
                                variant="success"
                                className="w-137"
                                onClick={() => navigate("/shoppingCart")}
                            >
                                {t("ProductPage.checkout")}
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <div className="zoom">
                            {" "}
                            <img src="/img/bigphoto.png" alt=""/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AccesoriessTabs heading={<h2>{t("ProductPage.remember")}</h2>}/>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

const PriceCard = ({
                       unit,
                       setUnit,
                       quantity,
                       setQuantity,
                       product,
                       setModalShow,
                       logistic,
                       setModalData,
                       priceLoading
                   }) => {
    const [cartReadyData, setCartReadyData] = useState(null);
    useEffect(() => {
        setCartReadyData(priceAndUnit(product, quantity, unit));
    }, [product, quantity, unit]);
    const priceUnitData = product?.availableUnits?.find(
        (element) => element.unit === product.priceList?.conditionUnit
    );
    const [loadingAvailability, setLoadingAvailability] = useState(false);
    const [availabilities, setAvailabilities] = useState(null);
    const userData = useSelector((state) => state.user.data);
    const {t, i18n} = useTranslation();

    return (
        <Col lg="auto">
            <div className="product-add-sticky price-wrapper">
                <div className="main-sticky-content">
                    <div className="border-bottom border-1 border-dark product-sticky-upper">
                        <div className="d-flex justify-content-center position-relative">
                            {
                                priceLoading ? <Spinner animation={'border'}/> :
                                    <PriceInPill
                                        className="h-min-40"
                                        discount="-30%"
                                        priceList={product?.priceList}
                                    />
                            }
                        </div>

                    </div>
                    <div className="product-sticky-lower">
                        <div className={"d-flex flex-column align-items-center"}>
              <span className="fz-12">
                {t("ProductPage.min")}:{" "}
                  {`${priceUnitData?.minOrderQuantity} ${priceUnitData?.shortUnitName}`}
              </span>
                            <div className="d-flex justify-content-center gap counter-dropdown-wrapper">
                                <Counter quantity={quantity} setQuantity={setQuantity}/>
                                {/*<UnitSelect*/}
                                {/*    data={product.availableUnits}*/}
                                {/*    value={unit}*/}
                                {/*    onSelect={(v) => setUnit(v.target.value)}*/}
                                {/*/>*/}
                                <CustomSelect
                                    options={product.availableUnits}
                                    value={unit}
                                    onChange={setUnit}
                                />
                            </div>
                            <span>
                {cartReadyData?.cartQuantity}{" "}
                                {cartReadyData?.defaultUnit?.shortDefaultUnitName}{" "}
                                {cartReadyData?.defaultUnit?.unit !==
                                cartReadyData?.baseUnit?.unit ? (
                                    <>
                                        = {cartReadyData?.baseQuantity}{" "}
                                        {cartReadyData?.baseUnit?.shortUnitName}
                                    </>
                                ) : null}
              </span>
                            <AddToCart
                                className="w-224 mt-4"
                                buttonText={t("ProductPage.basket")}
                                item={product}
                                selectedUnit={unit}
                                quantity={cartReadyData?.cartQuantity}
                                onAddingComplete={() => {
                                    setModalData(cartReadyData);
                                    setModalShow(true);
                                }}
                                setQuantity={(val) => {
                                    setQuantity(val);
                                }}
                                unit={cartReadyData?.defaultUnit?.unit}
                                productSumValue={`(${currencyFormatter(
                                    cartReadyData?.cartQuantity * product?.priceList?.minPrice,
                                    userData.customerData.canSeeProductPrice
                                )} ${product?.priceList?.currency})`}
                                priceLoading={priceLoading}
                            />

                            <Button
                                variant={"link"}
                                className="fz-14 fw-bold mt-3"
                                onClick={() => {
                                    setLoadingAvailability(true);

                                    checkAvailability(
                                        product,
                                        cartReadyData?.baseQuantity,
                                        cartReadyData?.baseUnit?.unit
                                    ).then((response) => {
                                        setAvailabilities(response.data.items[0].availabilities);
                                        setLoadingAvailability(false);
                                    });
                                }}
                            >
                                {t("ProductPage.availability")}
                            </Button>
                            <div className={"d-flex flex-column align-items-center"}>
                                {loadingAvailability ? (
                                    <Spinner animation={"border"} size={"sm"}/>
                                ) : availabilities ? (
                                    availabilities?.map((element, index) => (
                                        <div
                                            key={index}
                                            className={`fz-14 product-availability--${
                                                element.status === "API.CART.AVAILABLE_NOW"
                                                    ? "now"
                                                    : element.status === "API.CART.AVAILABLE_FROM"
                                                        ? "soon"
                                                        : element.status === "API.CART.NOT_AVAILABLE"
                                                            ? "never"
                                                            : ""
                                            }`}
                                        >
                                            {element.statusName}: {element.quantity.toFixed(3)}{" "}
                                            {element.unit}
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="second-gray-bg free-delivery fz-14 ">
                        <img src="/img/shipping.svg" alt=""/>
                        {t("ProductPage.free_delivery")}{" "}
                        {logistic?.freeDeliveryFromToOwnAddress}{" "}
                        {product?.priceList?.currency}
                    </div>
                </div>
            </div>
        </Col>
    );
};

const Product = () => {
    const {t, i18n} = useTranslation();
    const [product, setProduct] = useState();
    const [logistic, setLogistic] = useState();
    const [modalData, setModalData] = useState({});
    const [modalShow, setModalShow] = React.useState(false);
    const [accordionShow, setAccordionShow] = useState(true);
    const [priceLoading, setPriceLoading] = useState(false)
    const {productId} = useParams();

    const [quantity, setQuantity] = useState(1);
    const [unit, setUnit] = useState(product?.priceList?.conditionUnit);
    const [basePrice, setBasePrice] = useState();
    const accordionCollapse = document.querySelectorAll(".accordion-collapse");
    const [activeKeys, setActiveKeys] = useState(["0", "1", "2", "3"]);
    const toggleClass = () => {
        setAccordionShow(!accordionShow);

        if (!accordionShow) {
            setActiveKeys(["0", "1", "2", "3"]);
        } else {
            setActiveKeys([]);
        }

        accordionCollapse.classList.contains("collapse")
            ? accordionCollapse.classList.add("show")
            : accordionCollapse.classList.add("show");
    };
    const specRef = useRef(null);
    const scroll = useRef(null);

    function handleFiles(files) {

        const currentLang = i18n?.language
        const newFiles = []
        for (let i = 1; i < 5; i++) {
            let a = files.filter(file => file.categoryId === i && file.fileLang === currentLang)
            if(!a.length){
                a = files.filter(file => file.categoryId === i && file.fileLang === 'en')
            }
           newFiles.push(...a)
        }
        return newFiles
    }

    const getData = () => {
        axios.get("/minima-logistic/details").then((response) => {
            setLogistic(response.data.minimaLogistic);
        });
        axios
            .get("/product", {
                params: {
                    limit: 1,
                    page: 1,
                    filters: {
                        ["productId"]: {
                            "[]": productId,
                        },
                    },
                },
            })
            .then((response) => {
                let productData = response.data.items[0];
                productData = {
                    ...productData,
                    files: handleFiles(productData.files)
                }

                setProduct(productData);
                setUnit(productData?.priceList?.conditionUnit);
                setBasePrice(getBasePrice(productData));
                scroll.current._container.scrollTop = 0;

                const priceData = JSON.stringify({
                    products: [{
                        productSapIndex: productData.sapIndex,
                        quantity: 1,
                        unit: productData.priceList.conditionUnit,
                        selectUnit: productData.priceList.conditionUnit
                    }]
                })
                setPriceLoading(true)
                axios.post('/cash-register/price', priceData).then(res => {
                    const newPrice = res.data.items[0]

                    if (newPrice) {
                        productData.priceList = {
                            ...productData.priceList,
                            maxPrice: newPrice.mainUnitPriceNet,
                            minPrice: newPrice.mainUnitPriceNet,
                            conditionUnit: newPrice.unit,
                        }
                        setProduct(productData);

                    }
                    setPriceLoading(false)


                })
            });
    };

    useEffect(() => {
        getData();
    }, [productId]);

    const scrollToSpec = () => {
        scroll.current._container.scrollTop = specRef?.current.offsetTop;
        setActiveKeys((state) => [...state, "2"]);
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        window.addEventListener("load", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
            window.addEventListener("load", handleWindowSizeChange);
        };
    }, []);

    const toggleOneItem = (event, key) => {
        const isExpanded = activeKeys.some((item) => item === key);

        if (!isExpanded) {
            setActiveKeys((state) => [...state, key]);
        } else {
            setActiveKeys((state) => {
                const newKeys = state.filter((element) => element !== key);
                if (newKeys.length === 0) {
                    setAccordionShow(false);
                }
                return newKeys;
            });
        }
        // setActiveKeys(state => [...state, "0"]
    };

    const isMobile = windowWidth <= 992;
    // <IsMobile isMobile={isMobile} />

    const sortImages = (images) => {
        if (product?.category?.id !== 4) {
            return images;
        } else {
            return [
                ...images.filter((item) => item.category === "Packshot"),
                ...images.filter((item) => item.category !== "Packshot"),
            ];
        }
    };



    return (
        <>
            <BigModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                product={product}
                unit={unit}
                quantity={quantity}
                modalData={modalData}
                category={product?.category?.subCategories
                    ? (product?.category?.subCategories[0]?.name ?? "")
                    : (product?.category?.name ?? "")}
            />
            {product ? (
                <Row className="border-top border-1 border-dark">
                    <PerfectScrollbar ref={scroll}>
                        <Col>
                            <Container className="product-page ms-auto grey-bg product overflow-visible">
                                <Row className="justify-content-between">
                                    <Col className="product-page-content">
                                        <h4 className="fz-14 fw-bold">
                                            {product.category?.subCategories
                                                ? product.category?.subCategories[0]?.name
                                                : product.category?.name}
                                        </h4>
                                        <div className="header-wrapper">
                                            <h1>{product?.name}</h1>{" "}
                                            <Badge pill bg="dark" className="ms-3 fz-12 fw-bold">
                                                {product.color?.number}
                                            </Badge>
                                        </div>
                                        {/* <MDBslider/> */}
                                        <SwiperCom images={sortImages(product?.images)}/>

                                        <SpecificationList product={product}/>
                                        <div className="mt-30" onClick={scrollToSpec}>
                                            <Button variant={"link"} className="fw-bold">
                                                {t("ProductPage.more_info")}
                                            </Button>
                                        </div>
                                        {isMobile ? (
                                            <PriceCard
                                                logistic={logistic}
                                                setUnit={setUnit}
                                                unit={unit}
                                                quantity={quantity}
                                                product={product}
                                                setQuantity={setQuantity}
                                                basePrice={basePrice}
                                                setModalShow={setModalShow}
                                                setModalData={setModalData}
                                                priceLoading={priceLoading}
                                            />
                                        ) : null}
                                        <Row className="full-specification">
                                            <Col>
                                                <div className="text-end mb-2">
                                                    <Button
                                                        variant="link"
                                                        className="fw-bold fz-18 mb-1"
                                                        onClick={toggleClass}
                                                    >
                                                        {accordionShow
                                                            ? t("ProductPage.collapse")

                                                            : t("ProductPage.expand")
                                                        }
                                                    </Button>
                                                </div>
                                                <div className="collapse-item-wrapper">
                                                    <Accordion
                                                        defaultActiveKey={["0", "1", "2", "3"]}
                                                        alwaysOpen
                                                        activeKey={activeKeys}
                                                    >
                                                        {product?.isDedicatedProducts ? (
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header
                                                                    onClick={(e) => toggleOneItem(e, "0")}
                                                                >
                                                                    {t("ProductPage.accessories")}
                                                                </Accordion.Header>
                                                                <Accordion.Body className="show">
                                                                    <AccesoriessTabs/>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        ) : null}

                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header
                                                                onClick={(e) => toggleOneItem(e, "1")}
                                                            >
                                                                {t("ProductPage.variants")}
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <VariantsSlider
                                                                    category={product?.category?.id}
                                                                    series={product?.series?.id}
                                                                />
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header
                                                                ref={specRef}
                                                                onClick={(e) => toggleOneItem(e, "2")}
                                                            >
                                                                {t("ProductPage.specification")}

                                                            </Accordion.Header>
                                                            <Accordion.Body className="pt-2 accordion-specification">
                                                                <FullSpecification product={product}/>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header
                                                                onClick={(e) => toggleOneItem(e, "3")}
                                                            >
                                                                {t("ProductPage.attachments")}

                                                            </Accordion.Header>
                                                            <Accordion.Body className="pt-2 ">
                                                                <div className="attachments-wrapper">
                                                                    {product?.files.length
                                                                        ? product?.files?.map((file) => (
                                                                            <div className="attachment">
                                                                                <a
                                                                                    href={file.url}
                                                                                    download={file.originalFileName}
                                                                                    target="_blank"
                                                                                    className="btn-link btn icon-action rounded"
                                                                                >
                                                                                    {" "}
                                                                                    <img
                                                                                        src="/img/download.svg"
                                                                                        alt=""
                                                                                    />
                                                                                </a>
                                                                                <a
                                                                                    href={file.url}
                                                                                    target="_blank"
                                                                                    download={file.originalFileName}
                                                                                >
                                                                                    {file.categoryNameTrans}{" "}
                                                                                    {file.fileLang?.toUpperCase()}
                                                                                </a>
                                                                            </div>
                                                                        ))
                                                                        : t("ProductPage.no_attachments")}
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                    </Accordion>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {isMobile ? null : (
                                        <PriceCard
                                            setUnit={setUnit}
                                            unit={unit}
                                            quantity={quantity}
                                            product={product}
                                            setQuantity={setQuantity}
                                            basePrice={basePrice}
                                            setModalShow={setModalShow}
                                            setModalData={setModalData}
                                            logistic={logistic}
                                            priceLoading={priceLoading}

                                        />
                                    )}
                                </Row>
                            </Container>
                        </Col>
                    </PerfectScrollbar>
                </Row>
            ) : (
                <Spinner animation={"border"}/>
            )}
        </>
    );
};
export default Product;
