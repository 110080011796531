import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
const FooterElements = () => {
  // const navigate = useNavigate();
    const {t} = useTranslation();

  return (
    <div className="my-3 py-1 main-footer">
      <div className="copyright fw-bold">&#169; DECORA S.A.</div>
      <div><Link className="me-3">Terms of service</Link><a target={"_blank"} href={"/files/instrukcja_obslugi_B2B.pdf"}>{t("Menu.manual")}</a></div>
    </div>
    
  );
};
export default FooterElements;
