import React, {useEffect, useState} from "react";
import {Alert, Col, Container, Row, Spinner} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import LoginLeftColumn from "../components/loginLeftColumn";
import {useForm} from "react-hook-form";
import axios from "axios";
import { useTranslation } from "react-i18next";
import '../i18n';
const LoginNewPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  const {register, handleSubmit, getValues, formState:{ errors }} = useForm();
  const [loading, setLoading] = useState(false)

  const onSubmit = data => {

    setLoading(true)
    axios.post(
        "/user/set-password",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
    ).then(response => {
      if (response.status === 200) {

        navigate("/login-password-changed");

      }
      setLoading(false)
    }).catch(error => {


      setLoading(false)

    })
  };

  return (
    <>
      <Container fluid className="main-container login">
        <Row className="my-auto login-wrapper">
          <LoginLeftColumn />
          <Col
            xs="12"
            lg="6"
            xl="7"
            className="d-flex justify-content-center flex-column align-items-center text-center"
          >
            <div>
              <h2>{t("Welcome.reset_btn")}</h2>
            
              <Form
                  onSubmit={handleSubmit(onSubmit)}

              >
                <Form.Group className="mt-5 mb-4 pb-1 user password" controlId="user-email">
                  <Form.Control
                    type="password"
                    placeholder={t("Welcome.new_pass")}
                    className=""
                    {...register("password", { required: true, } )}
                  />
                </Form.Group>
                <Form.Group className="mb-5 user password" controlId="user-email">
                  <Form.Control
                    type="password"
                    placeholder={t("Welcome.repeat_pass")}
                    className="mb-4"
                    {...register("confirmPassword",{ validate: value => value === getValues("password") || t("Welcome.compatibility_pass"), required: true, })}
                  />
                </Form.Group>
                <Button variant="success" type="submit" className="w-100" disabled={loading} >

                  {loading ? <Spinner size={"sm"} animation="border"/> : t("Welcome.reset_btn")}

                </Button>
                <Alert

                    variant={"danger"}
                    show={errors?.confirmPassword?.message ? true :false}
                >
                  {/*{message}*/}
                  {errors?.confirmPassword?.message}

                </Alert>
               </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default LoginNewPassword;