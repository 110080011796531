import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
export const getUserData = createAsyncThunk(
    "user/getUserData", async (_, thunkAPI) => {
        const response = await axios.get("/user/data");
        return await response.data
    }
)


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        token: localStorage.getItem("token"),
        data: null
    },
    reducers: {
        setToken: (state, action) => {

            state.token = action.payload
        },
        logOut: (state) => {
            state.token = null;
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            window.location.assign("/login")
        },

    },
    extraReducers: (builder)=>{
        builder.addCase(getUserData.fulfilled, (state, {payload})=>{
            state.data = payload
            localStorage.setItem("userSapIndex", payload.sapIndex)
        })

    }
})

// Action creators are generated for each case reducer function
export const {setToken, logOut} = userSlice.actions

export default userSlice.reducer