import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import {useEffect, useState} from "react";
import {ProgressBar} from "react-bootstrap";


const ScanditScanner = ({onResult}) => {

    const [loadingProgress, setLoadingProgress] = useState(0);

    async function runScanner() {
        SDCCore.loadingStatus.subscribe(info => {
            setLoadingProgress(info.percentage)
        })

        await SDCCore.configure({
            licenseKey: licenseKey,
            libraryLocation: 'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/',
            moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
        });

        const userSapIndex = localStorage.getItem("userSapIndex")


        const context = await SDCCore.DataCaptureContext.createWithOptions({
            deviceName: userSapIndex,
        });


        const camera = SDCCore.Camera.default;
        await context.setFrameSource(camera);

        const settings = new SDCBarcode.BarcodeCaptureSettings();

        const feedback = SDCBarcode.BarcodeCaptureFeedback.default
        feedback.success = new SDCCore.Feedback(null, null)
        settings.enableSymbologies([
            SDCBarcode.Symbology.EAN13UPCA
        ]);


        const symbologySetting = settings.settingsForSymbology(SDCBarcode.Symbology.Code39);
        symbologySetting.activeSymbolCounts = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

        const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(context, settings);
        await barcodeCapture.setFeedback(feedback)
        await barcodeCapture.setEnabled(false);
        barcodeCapture.addListener({
            didScan: async (barcodeCapture, session) => {

                await barcodeCapture.setEnabled(false);
                const barcode = session.newlyRecognizedBarcodes[0];
                onResult(barcode.data);
                // await barcodeCapture.setEnabled(true);

            },
        });

        const view = await SDCCore.DataCaptureView.forContext(context);
        view.connectToElement(document.getElementById("data-capture-view"));


        const barcodeCaptureOverlay =
            await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
                barcodeCapture,
                view,
                SDCBarcode.BarcodeCaptureOverlayStyle.Frame
            );

        const viewfinder = new SDCCore.RectangularViewfinder(
            SDCCore.RectangularViewfinderStyle.Square,
            SDCCore.RectangularViewfinderLineStyle.Light
        );

        await barcodeCaptureOverlay.setViewfinder(viewfinder);

        await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
        await barcodeCapture.setEnabled(true);
    }

    useEffect(() => {
        runScanner().catch((error) => {
            console.error(error);
        });
    }, []);


    return (
        <>
            {
                loadingProgress === 100 || loadingProgress === 0 ? null :
                    <ProgressBar max={100} now={loadingProgress} variant={"success"}/>


            }
            <div id="data-capture-view"></div>

        </>
    )
};

const licenseKey = "AaNDmbuBL16VN0WAriI6WTsju2QaAIu9+y+k7vEbnh/XY69b7Fz8nYFHy/YcBekyAFVWCfd3bXrDWC3wz3G/wk5TsoTiRshZ4UHGUQtxVexEfXhdPELXQOg5mPw5fRM6ZXbqyIQ6KGm/Mj//4RyRGhhGazlMBzTNFzn1aIw6Sju5vTfs/LlmCjK+qnUYBG56qFiP/apf8I5cVovwA6d3g1qnWfYh7OBnqhXcBnF5YewVFd3Sd9Usg0HMIDHOFSNd0q+zkLuG31xPr8jKuzTYkcN4MfFVXBmYaYMV1HYAAAqnamvuAviPT1b5kHCSRnL76wPd3yTSu5MHBJSjEg5I1jvtmeV9y6n472IFd85CJ1sA5iO3Mwrf/H1xMWA8F1woPBxQlQiFoqBgV8YDUWFfEcU11+rNDUGTCKf+wmKKUGwnYTjQBw9+4fub/FVWdQ7z1uXuxkwwtiHFWpSFftzYbO18OjPPcvIO0Sqca4w3LtZYeF+LanK2COHWlj+AXj/QyEmklzqdvk1ZBqxflxfkV09a2EECJ1GgxygtLWqxEgkCEPk9FgN/CV8NFPRN+cSqkbAlFqFzeyvtLS+qDHZjMoZRyrNcyDhOpXP2riLbCWrBcr5GggCZp0DLOT9wfjsLEuBkICdlP7WK51ELGMxy3ifoE3D9PluGSbDADWwAPoejGnlsrrOm1cndUZQ34WuKQ11G5fadqQu4lvbEjM1+i36Q4B7tCVGsR1hjR9aj3IgB3JmBICEjHUk8z9Gj6ilj7k5V778j07n1vsxCH/RXweFB7Egmhj5uX4jNrrfdKDQKAWWP/y+7Jfc+IQUvtA8kSdkXe+Mo";

export default ScanditScanner;

