import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import LanguagePicker from "../languagePicker/languagePicker";

const Navigation = () => {
    // const navigate = useNavigate();

    return (
        <Navbar fixed="top" id="navbar" className="navbar-login">
            <Container fluid className="px-0">
                <Navbar.Brand href="#home">
                    {" "}
                    <img className={"logo"} src={`/img/${window.location.host === 'b2bewifoam.de' ? 'ewifoam' : 'logo'}.svg`} alt=" "/>

                </Navbar.Brand>
                <LanguagePicker/>

            </Container>
        </Navbar>
    );
};
export default Navigation;
