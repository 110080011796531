import Form from "react-bootstrap/Form";
import CustomTableDescription from "../customTable/customTableDescription";
import {React, useContext, useState} from "react";
import {currencyFormatter} from "../../helpers/currencyTools";
import {useSelector} from "react-redux";
import {RemoveOffer} from "./RemoveOffer";
import {useTranslation} from "react-i18next";
import round from "../../helpers/round";


function Offer({
                   innerRef, innerRef2, innerRef3, innerRef4, offer, onCheck, selected,

               }) {

    const userData = useSelector((state) => state.user.data);
    const {t} = useTranslation()

    return (<div className="custom-table-row offer ">
            <div className="custom-table-checkbox">
                <Form.Check
                    onChange={(event) => onCheck(event, offer, offer.reduce((accumulator, currentVal) => accumulator + currentVal?.productPrice?.valueNet, 0))}
                    checked={selected.some(element => element[0].offerId === offer[0].offerId)}
                />
            </div>
            <div className={"d-flex flex-column p-0 w-100"}
                 style={{borderRight: "unset"}}
            >
                {offer.map((element, index) => {
                    const {grossWeight, volume} = element.product.technicalParameters.logisticData
                    const priceSum = element?.productPrice?.valueNet
                    const weight = round(grossWeight * element.quantity)
                    const orderVolume = round((volume * element.quantity) / 1000000)
                    return <div
                        className={`d-flex flex-column flex-lg-row w-100 product mb-lg-0 ${index !== (offer.length - 1) ? "mb-2" : ""}`}>
                        <CustomTableDescription
                            className={"d-flex"}


                            item={element}

                            minOrder={`${element.unitConverter?.minOrderQuantity} ${element.unitConverter?.unitName}`}
                        />
                        <div
                            className="custom-table-availabilty"


                        >
                            {/* klasa available do dostępnego, zmienia kolor kolka */}
                            {/* klasa later do dostepnego pozniej */}
                            <div className={element.availableNow ? "available" : "later"}> {element.status}</div>
                        </div>
                        <div
                            className="custom-table-price" ref={innerRef}
                            data-title={t("SummaryTable.net")}


                        >
                            <div className="price">
                                {`${currencyFormatter(element?.productPrice?.mainUnitPriceNet, userData.customerData.canSeeProductPrice)} ${element?.productPrice?.currency ?? ""} / ${element?.productPrice?.unit ?? ""}`}
                            </div>

                        </div>
                        <div className="custom-table-counter" ref={innerRef2}
                             data-title={t("SummaryTable.quantity")}


                        >
                            <div className={'d-block'}>
                                <span>
                                    {element.quantity} {element?.productPrice?.unit ?? ""}
                                </span>
                                <br/>
                                {
                                    userData?.customerData.notSeeProductWeight ? null : <span >
                                    {t('Orders.weight')} {weight} kg
                               </span>
                                }

                                <br/>
                                {
                                    userData?.customerData.notSeeProductVolume ? null : <span >
                                    {t('Orders.volume')} {orderVolume} m<sup>3</sup>
                                </span>
                                }

                            </div>

                        </div>

                        <div className="custom-table-sum" ref={innerRef3}
                             data-title={t("SummaryTable.net_total")}

                        >

                            {currencyFormatter(priceSum, userData.customerData.canSeeProductPrice)}{element?.productPrice?.currency}

                        </div>
                    </div>
                })}
            </div>

            <div className="custom-table-delete " ref={innerRef4}>
                <RemoveOffer offerId={offer[0].offerId}/>
            </div>
            <div className={"offer-name p-absolute "}>
                Oferta: {offer[0]?.offerName}
            </div>
        </div>);
}

export default Offer;
