import Button from "react-bootstrap/Button";
import SubTable from "../customTable/subTable";
import {Link} from "react-router-dom";
import {Alert, Badge} from "react-bootstrap";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {currencyFormatter} from "../../helpers/currencyTools";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ProductOrderNumber from "../cart/productOrderNumber";

const SummaryTable = ({
                          items,
                          setItems,
                          offers,
                          netShipping,
                          currency,
                          grossWholeOrderValue,
                          taxValue,
                          netWholeOrderValue,
                          taxWholeOrderValue,
                          productOrderNumber = null,
                          sumVolume,
                          sumWeight
                      }) => {
    const [products, setProducts] = useState([])
    const {t} = useTranslation();
    const useResize = (myRef) => {
        const [width, setWidth] = useState(0);
        const [width2, setWidth2] = useState(0);
        const [width3, setWidth3] = useState(0);
        const [width4, setWidth4] = useState(0);

        const handleResize = useCallback(() => {
            setWidth(myRef?.current.offsetWidth);
            setWidth2(myRef?.current.offsetWidth);
            setWidth3(myRef?.current.offsetWidth);
            setWidth4(myRef?.current.offsetWidth);
        }, [myRef]);

        useEffect(() => {
            if (window.innerWidth > 992) {
                handleResize();
                window.addEventListener("load", handleResize);
                window.addEventListener("resize", handleResize);

                return () => {
                    window.removeEventListener("load", handleResize);
                    window.removeEventListener("resize", handleResize);
                };
            }
        }, [myRef, handleResize, window.innerWidth]);

        return {width, width2, width3, width4};
    };
    const componentRef = useRef();
    const componentRef2 = useRef();
    const componentRef3 = useRef();

    const {width} = useResize(componentRef);
    const {width2} = useResize(componentRef2);
    const {width3} = useResize(componentRef3);

    const widthSum = width + width2 + width3 + parseInt("2px");
    const subTableWidth = {
        width: widthSum,
    };
    const priceCounterWidth = width + width2;
    const subTableFirstCol = {
        width: priceCounterWidth,
    };
    const [modalShow, setModalShow] = React.useState(false);

    const [showComment, setShowComment] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        window.addEventListener("load", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
            window.addEventListener("load", handleWindowSizeChange);
        };
    }, [windowWidth]);

    const isMobile = windowWidth <= 992;
    const userData = useSelector((state) => state.user.data);

    const handleImageSrc = (product) => {
        if (!product?.images) {
            return ""
        }
        let newImages = [...product?.images?.filter(item => item.category === "Packshot"), ...product?.images?.filter(item => item.category !== "Packshot")]
        return newImages[0]?.resized ? newImages[0]?.resized?.galleryThumb?.default : newImages[0]?.default
    }

    return isMobile ? (<>
        <div className="custom-table">
            <div className="equal-cols with-bordered-headers">
                {items.map((product, index) => {
                    const {cancelPosition} = product
                    return (<div className={`custom-table-row `} key={index}>
                        {cancelPosition ? <Alert variant={'danger'}
                                                 className={'text-center d-flex justify-content-center align-items-center p-1'}>
                            {t('Orders.cancelled_position')}
                        </Alert> : null}
                        <div className="custom-table-col">

                            <span
                                className={`fw-bold ${cancelPosition ? 'text-cancel' : ''}`}>{product?.product?.name}</span>

                        </div>
                        {product?.product?.ean ? <div className="custom-table-col">
                                <span className="fw-bold text-uppercase d-lg-none ">
                                    ean:
                                </span>
                            <span>{product?.product?.ean}</span>
                        </div> : null}
                        {product?.product?.brand?.name ? <div className="custom-table-col">
                                    <span className="fw-bold text-uppercase d-lg-none ">
                                      {t("SummaryTable.brand")}:
                                    </span>
                            <span>{product?.product?.brand?.name}</span>
                        </div> : null}


                        {product.unitConverter?.minOrderQuantity ? (<div className="custom-table-col">
                      <span className="fw-bold text-uppercase d-lg-none ">
                        {t("List.min_order")}:
                      </span>
                            <span>{`${product.unitConverter?.minOrderQuantity} ${product.unitConverter?.unitName}`}</span>
                        </div>) : null}

                        {product.status ? (<div className="custom-table-col">
                      <span className="fw-bold text-uppercase d-lg-none ">
                        {t("SummaryTable.status")}:
                      </span>
                            <span>
                        <div>
                          {product.availableNow ? (<div className="available">{product.status}</div>) : (
                              <div className="later">{product.status}</div>)}
                        </div>
                      </span>
                        </div>) : null}
                        <div className={`custom-table-col ${cancelPosition ? 'text-cancel' : ''}`}>
                            <span className="fw-bold text-uppercase d-lg-none ">
                              {t("SummaryTable.net")}:
                            </span>
                            <span>
                              {currencyFormatter(product.mainUnitPriceNet, userData.customerData.canSeeProductPrice)}{" "}
                                {product.currency} / {product.unit}
                            </span>
                        </div>
                        <div className={`custom-table-col ${cancelPosition ? 'text-cancel' : ''}`}>
                            <span className="fw-bold text-uppercase d-lg-none ">
                              {t("SummaryTable.quantity")}:
                            </span>
                            <span>
                              {" "}
                                {product?.quantity} {product?.unit}
                            </span>
                        </div>
                        {product.weight && !userData?.customerData.notSeeProductWeight ?
                            <div className={`custom-table-col ${cancelPosition ? 'text-cancel' : ''}`}>
                            <span className="fw-bold text-uppercase d-lg-none ">
                              {t('Orders.weight')}:
                            </span>
                                <span>
                              {" "}
                                    {product?.weight} kg
                            </span>
                            </div> : null}
                        {product.orderVolume && !userData?.customerData.notSeeProductVolume ?
                            <div className={`custom-table-col ${cancelPosition ? 'text-cancel' : ''}`}>
                            <span className="fw-bold text-uppercase d-lg-none ">
                              {t('Orders.volume')}:
                            </span>
                                <span>
                              {" "}
                                    {product?.orderVolume}m<sup>3</sup>
                            </span>
                            </div> : null}

                        <div className={`custom-table-col ${cancelPosition ? 'text-cancel' : ''}`}>
                            <span className="fw-bold text-uppercase d-lg-none ">
                              {t("SummaryTable.net_total")}:
                            </span>
                            <span>
                              {" "}
                                {currencyFormatter(product.valueNet, userData.customerData.canSeeProductPrice)}{" "}
                                {product.currency}
                            </span>
                        </div>
                        {!productOrderNumber ? null : productOrderNumber === 'input' ?
                            <ProductOrderNumber product={product} index={index}
                                                setItems={setItems}/> : product.customerOrderNumber ? <div>
                                        <span
                                            className="fw-bold text-uppercase d-lg-none ">{t('Orders.order_number_client')}:</span>&nbsp;{product.customerOrderNumber}
                            </div> : null}
                    </div>);
                })}
                {offers.map((offer, offerIndex) => {
                    return offer.map((product, index) => {
                        const isLast = index === offer.length - 1;

                        return (<>
                            <div
                                className={`custom-table-row text-center offer--mobile`}
                                key={index}
                            >
                                <div className="custom-table-col">
                        <span className="fw-bold text-uppercase d-lg-none ">
                          {t("SummaryTable.name")}:
                        </span>
                                    <span>{product?.product?.name}</span>
                                </div>
                                <div className="custom-table-col">
                        <span className="fw-bold text-uppercase d-lg-none ">
                          ean:
                        </span>
                                    <span>{product?.product?.ean}</span>
                                </div>
                                <div className="custom-table-col">
                        <span className="fw-bold text-uppercase d-lg-none ">
                          {t("SummaryTable.brand")}:
                        </span>
                                    <span>{product?.product?.brand?.name}</span>
                                </div>
                                {product.unitConverter?.minOrderQuantity ? (<div className="custom-table-col">
                          <span className="fw-bold text-uppercase d-lg-none ">
                            {t("List.min_order")}:
                          </span>
                                    <span>{`${product.unitConverter?.minOrderQuantity} ${product.unitConverter?.unitName}`}</span>
                                </div>) : null}

                                {product.status ? (<div className="custom-table-col">
                          <span className="fw-bold text-uppercase d-lg-none ">
                            {t("SummaryTable.status")}:
                          </span>
                                    <span>
                            <div>
                              {product.availableNow ? (<div className="available">
                                  {product.status}
                              </div>) : (<div className="later">{product.status}</div>)}
                            </div>
                          </span>
                                </div>) : null}
                                <div className="custom-table-col">
                        <span className="fw-bold text-uppercase d-lg-none ">
                          {t("SummaryTable.net")}:
                        </span>
                                    <span>
                          {currencyFormatter(product.mainUnitPriceNet, userData.customerData.canSeeProductPrice)}{" "}
                                        {product.currency} / {product.unit}
                        </span>
                                </div>
                                <div className="custom-table-col">
                        <span className="fw-bold text-uppercase d-lg-none ">
                          {t("SummaryTable.quantity")}:
                        </span>

                                    <span>
                          {" "}
                                        {product?.quantity} {product?.unit}
                        </span>
                                </div>
                                {product.weight && !userData?.customerData.notSeeProductWeight ?
                                    <div className={`custom-table-col `}>
                            <span className="fw-bold text-uppercase d-lg-none ">
                              {t('Orders.weight')}:
                            </span>
                                        <span>
                              {" "}
                                            {product?.weight} kg
                            </span>
                                    </div> : null}
                                {product.weight && !userData?.customerData.notSeeProductVolume ?
                                    <div className={`custom-table-col `}>
                            <span className="fw-bold text-uppercase d-lg-none ">
                              {t('Orders.volume')}:
                            </span>
                                        <span>
                              {" "}
                                            {product?.orderVolume}m<sup>3</sup>
                            </span>
                                    </div> : null}
                                <div className="custom-table-col">
                        <span className="fw-bold text-uppercase d-lg-none ">
                          {t("SummaryTable.net_total")}:
                        </span>
                                    <span>
                          {" "}
                                        {currencyFormatter(product.valueNet, userData.customerData.canSeeProductPrice)}{" "}
                                        {product.currency}
                        </span>
                                </div>
                            </div>
                            {isLast ? (<div
                                className={"custom-table-row text-center offer-name--mobile"}
                            >
                                {product.offerName}
                            </div>) : null}
                        </>);
                    });
                })}
            </div>
        </div>
        <SubTable
            deliveryPrice={netShipping}
            orderNetTotal={netWholeOrderValue}
            tax={taxWholeOrderValue}
            taxRate={taxValue}
            orderValue={grossWholeOrderValue}
            currency={currency}
            sumWeight={sumWeight}
            sumVolume={sumVolume}

        />
    </>) : (<>
        <div className="custom-table second-mobile-card with-subtable product cashbox">
            {items?.map((product, index) => {
                // const product = cart?.items[0]?.cartProducts.find(element => element.product.sapIndex === item.productSapIndex)
                const imgUrl = handleImageSrc(product?.product);
                const {cancelPosition} = product

                return (<div className={`custom-table-row `} key={index}>
                    <div className="custom-table-description">
                        <div className="image">
                            <div className="relative-padding">
                                {cancelPosition ? <Alert variant={'danger'}
                                                         className={'text-center d-flex justify-content-center align-items-center'}>{t('Orders.cancelled_position')} </Alert> : product.product?.blockProductView ?
                                    <img src={imgUrl ?? ""} alt=""/> : <Link to={"/product/" + product.product.id}>
                                        <img src={imgUrl ?? ""} alt=""/>
                                    </Link>}

                            </div>

                        </div>
                        <div>
                            {product.product?.blockProductView ?
                                <h3 className={`h3 ${cancelPosition ? 'text-cancel' : ''}`}>
                                    {product?.product?.name}
                                    <Badge
                                        pill
                                        bg="dark"
                                        className="fz-12 fw-bold smaller-badge"
                                    >
                                        {product?.product?.color?.number}
                                    </Badge>
                                </h3> : <Link to={"/product/" + product.product.id}>
                                    <h3 className={`h3 ${cancelPosition ? 'text-cancel' : ''}`}>
                                        {product?.product?.name}
                                        <Badge
                                            pill
                                            bg="dark"
                                            className="fz-12 fw-bold smaller-badge"
                                        >
                                            {product?.product?.color?.number}
                                        </Badge>
                                    </h3>
                                </Link>}

                            <div className={`parameters `}>
                                {product?.product?.ean ? <div>
                                    <b>EAN: </b>
                                    {product?.product?.ean}
                                </div> : null}
                                {product?.product?.brand?.name ? <div>
                                    <b>{t("List.brand")}: </b>
                                    {product?.product?.brand?.name}
                                </div> : null}

                                {product.unitConverter?.minOrderQuantity ? (<div>
                                    <b>{t("List.min_order")}:</b>{" "}
                                    {`${product.unitConverter?.minOrderQuantity} ${product.unitConverter?.unitName}`}
                                </div>) : null}
                            </div>
                            {!productOrderNumber ? null : productOrderNumber === 'input' ?
                                <ProductOrderNumber product={product} index={index}
                                                    setItems={setItems}/> : product.customerOrderNumber ? <div>
                                    <b className={'text-uppercase'}>
                                        {t('Orders.order_number_client')}: </b>{product.customerOrderNumber}
                                </div> : null}
                        </div>
                    </div>
                    {product.status ? (<div className="custom-table-availabilty">
                        {/* /!* klasa available do dostępnego, zmienia kolor kolka *!/
                   /!* klasa later do dostepnego pozniej *!/ */}

                        {product.availableNow ? (<div className="available">{product.status}</div>) : (
                            <div className="later">{product.status}</div>)}
                    </div>) : null}

                    <div
                        className="custom-table-price flex-column"
                        ref={componentRef}
                        data-title={t("SummaryTable.net")}
                    >
                        <div className={`price ${cancelPosition ? 'text-cancel' : ''}`}>
                            {currencyFormatter(product.mainUnitPriceNet, userData.customerData.canSeeProductPrice)}{" "}
                            {product.currency} / {product.unit}
                        </div>
                        {/*<div className="price-per-product">*/}
                        {/*  8,29 € / opakowanie*/}
                        {/*</div>*/}
                    </div>
                    <div
                        className={`custom-table-counter flex-column gap-10 `}
                        ref={componentRef2}
                        data-title={t("SummaryTable.quantity")}

                    >
                        <span className={`${cancelPosition ? 'text-cancel' : ''}`}>
                        {product?.quantity} {product?.unit}

                        </span>
                        {product?.weight && !userData?.customerData.notSeeProductWeight ?
                            <span>{product.weight} kg</span> : null}
                        {product?.orderVolume && !userData?.customerData.notSeeProductVolume ? <span>
                                {product.orderVolume} m< sup>3< /sup>
                            </span> : null}
                    </div>
                    <div className={`custom-table-sum`} ref={componentRef3}
                         data-title={t("SummaryTable.net_total")}

                    >
<span className={`fs-5 ${cancelPosition ? 'text-cancel' : ''}`}>
                            {currencyFormatter(product.valueNet, userData.customerData.canSeeProductPrice)}{" "}
    {product.currency}
</span>


                    </div>
                </div>);
            })}
            {offers?.map((offer, offerIndex) => {
                return offer?.map((product, index) => {
                    // const product = cart?.items[0]?.cartProducts.find(element => element.product.sapIndex === item.productSapIndex)
                    const imgUrl = handleImageSrc(product?.product);
                    // console.log(item, product)
                    const isLast = index === offer.length - 1;

                    return (<div
                        className={`custom-table-row offer ${isLast ? "  offer--last " : ""}`}
                        key={index}
                        style={{
                            borderTop: `${index === 0 ? "1px solid #3179CE" : "none"}`,
                            borderBottom: `${isLast ? "1px solid #3179CE" : "1px solid #e6e6e6"}`,
                        }}
                    >
                        <div className="custom-table-description">
                            <div className="image">
                                <div className="relative-padding">

                                    {product.product?.blockProductView ? <img src={imgUrl ?? ""} alt=""/> :
                                        <Link to={"/product/" + product.product.id}>
                                            <img src={imgUrl ?? ""} alt=""/>
                                        </Link>}
                                </div>

                            </div>
                            <div>
                                {product.product?.blockProductView ? <h3 className="h3">
                                    {product?.product?.name}
                                    <Badge
                                        pill
                                        bg="dark"
                                        className="fz-12 fw-bold smaller-badge"
                                    >
                                        {product?.product?.color?.number}
                                    </Badge>
                                </h3> : <Link to={"/product/" + product.product.id}>
                                    <h3 className="h3">
                                        {product?.product?.name}
                                        <Badge
                                            pill
                                            bg="dark"
                                            className="fz-12 fw-bold smaller-badge"
                                        >
                                            {product?.product?.color?.number}
                                        </Badge>
                                    </h3>
                                </Link>}

                                <div className="parameters">
                                    {product?.product?.ean ? (<div>
                                        <b>EAN: </b>
                                        {product?.product?.ean}
                                    </div>) : null}
                                    {product?.product?.brand?.name ? (<div>
                                        {t("List.brand")}:<b>: </b>
                                        {product?.product?.brand?.name}
                                    </div>) : null}
                                    {product.unitConverter?.minOrderQuantity ? (<div>
                                        {t("List.brand")}:<b></b>{" "}
                                        {`${product.unitConverter?.minOrderQuantity} ${product.unitConverter?.unitName}`}
                                    </div>) : null}
                                </div>
                            </div>
                        </div>
                        {product.status ? (<div className="custom-table-availabilty">
                            {/* /!* klasa available do dostępnego, zmienia kolor kolka *!/
                   /!* klasa later do dostepnego pozniej *!/ */}

                            {product.availableNow ? (<div className="available">{product.status}</div>) : (
                                <div className="later">{product.status}</div>)}
                        </div>) : null}

                        <div
                            className="custom-table-price flex-column"
                            ref={componentRef}
                        >
                            <div className="price">
                                {currencyFormatter(product.mainUnitPriceNet, userData.customerData.canSeeProductPrice)}{" "}
                                {product.currency} / {product.unit}
                            </div>
                            {/*<div className="price-per-product">*/}
                            {/*  8,29 € / opakowanie*/}
                            {/*</div>*/}
                        </div>
                        <div
                            className="custom-table-counter flex-column gap-10"
                            ref={componentRef2}
                        >
                            <span>
                            {product?.quantity} {product?.unit}

                            </span>
                            {product?.weight && !userData?.customerData.notSeeProductWeight ?
                                <span>
                                                             `${product.weight} kg`
                            </span>
                                : null}
                            {product?.orderVolume  && !userData?.customerData.notSeeProductVolume ?
                                <span><>
                                  {product.orderVolume} m< sup>3< /sup>
                                  </>
                                    </span>
                                : null}


                        </div>
                        <div className="custom-table-sum" ref={componentRef3}>
                            <span className={`fs-5`}>
                             {currencyFormatter(product.valueNet, userData.customerData.canSeeProductPrice)}{" "}
                                {product.currency}
                            </span>


                        </div>
                        {isLast ? (<span className={"offer-name"}>{product.offerName}</span>) : null}
                    </div>);
                });
            })}
        </div>
        <div className="subtable ms-auto" style={subTableWidth}>
            {
                sumVolume || sumWeight ? <div className="subtable-wrapper">
                        {
                            userData?.customerData.notSeeProductWeight ? null : <div className="subtable-row">
                                <div className="text-end pe-4" style={subTableFirstCol}>
                                    <span>{t('Orders.weight')}:</span>
                                </div>
                                <div className="text-center">
                            <span>
                              {sumWeight} kg
                            </span>
                                </div>
                            </div>
                        }
                        {
                            userData?.customerData.notSeeProductVolume ? null : <div className="subtable-row">
                                <div className="text-end pe-4" style={subTableFirstCol}>
                                    <span>{t('Orders.volume')}:</span>
                                </div>
                                <div className="text-center">
                            <span>
                              {sumVolume} m<sup>3</sup>
                            </span>
                                </div>
                            </div>
                        }


                </div> : null
            }

            <div className="subtable-wrapper">
                <div className="subtable-row">
                    <div className="text-end pe-4" style={subTableFirstCol}>
                        <span>{t("SummaryTable.delivery_cost")}:</span>
                    </div>
                    <div className="text-center">
                <span>
                  {currencyFormatter(netShipping, userData.customerData.canSeeProductPrice)}{" "}
                    {currency}
                </span>
                    </div>
                </div>
            </div>
            <div className="subtable-wrapper">
                <div className="subtable-row">
                    <div className="text-end pe-4" style={subTableFirstCol}>
                        <span>{t("SummaryTable.net_value")}:</span>
                    </div>
                    <div className="text-center fz-16">
                        <span>{`${currencyFormatter(netWholeOrderValue, userData.customerData.canSeeProductPrice)} ${currency}`}</span>
                    </div>
                </div>
                <div className="subtable-row fw-normal">
                    <div className="text-end pe-4" style={subTableFirstCol}>
                <span className="">
                  {t("Orders.tax")}: ({taxValue}%):
                </span>
                    </div>
                    <div className="text-center">
                        <span>{`${currencyFormatter(taxWholeOrderValue, userData.customerData.canSeeProductPrice)} ${currency}`}</span>
                    </div>
                </div>
            </div>
            <div className="subtable-wrapper subtable-single-wrapper">
                <div className="subtable-row fz-24">
                    <div>
                <span>
                  {t("Orders.payment")}:{" "}
                    {`${currencyFormatter(grossWholeOrderValue, userData.customerData.canSeeProductPrice)} ${currency}`}
                </span>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    </>);
}
export default SummaryTable