const priceAndUnit = (item, quantity, unit) => {
    const {availableUnits, basePriceList, priceList, technicalParameters} = item;
    const defaultU =  priceList?.conditionUnit
    const defaultUnit = availableUnits.find(element => element.unit === defaultU);

    const baseUnit = availableUnits.find(element => element.unit === (technicalParameters?.logisticData?.baseUnit ? technicalParameters?.logisticData?.baseUnit : priceList?.conditionUnit));
    const selectedUnit = availableUnits?.find(
        (element) => element.unit === unit
    );
    //quantity of base unit in selected
    const inBaseUnit = quantity * (selectedUnit?.counter / selectedUnit?.denominator);
    //quantity of 1 base unit in default unit
    const inDefaultUnit = defaultUnit?.counter / defaultUnit?.denominator;
    const num = (inBaseUnit / inDefaultUnit) / defaultUnit?.multiple
    const cartQuantity = Math.ceil(Math.round((num + Number.EPSILON) * 1000) / 1000) * defaultUnit?.multiple;

    const baseQuantity = Number.parseFloat(cartQuantity * (defaultUnit?.counter / defaultUnit?.denominator))

    return {
        cartQuantity: cartQuantity.toFixed(3) === "NaN" ? "-" : cartQuantity.toFixed(3),
        defaultUnit: defaultUnit,
        baseUnit: baseUnit,
        baseQuantity: baseQuantity.toFixed(3) === "NaN" ? "-" : baseQuantity.toFixed(3),
        selectedUnit: selectedUnit
    };
};
export default priceAndUnit;
