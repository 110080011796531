import CustomTable from "../components/table/customTable";
import {Link, useNavigate} from "react-router-dom";
import {ReactComponent as Receipt} from "../svg/receipt_long_black_24dp.svg";
import {ReactComponent as Close} from "../svg/close.svg";
import HeaderBar from "../components/headerBar/headerBar";
import Button from "react-bootstrap/Button";
import axios from "axios";
import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Col, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {debounce} from "lodash";
import useDidUpdateEffect from "../customHooks/useDidUpdateEffect";
import PaginationComponent from "../components/pagination";
import { useTranslation } from "react-i18next";


const Reclamations = () => {
const { t } = useTranslation();  

    const navigate = useNavigate()
    const columns = [
      {
        title: t("Reclamations.nr"),
        dataKey: "complaintNumber",
        width: "14.3%",
      },
      {
        title: t("Reclamations.nr_order"),
        dataKey: "orderNumber",
        width: "14.3%",
      },
      {
        title: t("Reclamations.nr_invoice"),
        dataKey: "invoiceNumber",
        width: "14.3%",
      },
      {
        title: t("Reclamations.date_complaint"),
        dataKey: "createComplaintDate",
        width: "14.3%",
      },
      {
        title: t("Reclamations.date_order"),
        dataKey: "createOrderDate",
        width: "14.3%",
      },
      {
        title: t("Reclamations.status"),
        dataKey: "status",
        width: "14.3%",
      },
      {
        title: "",
        dataKey: "id",
        width: "14.3%",
        render: (value) => {
          return (
            <div className={"w-100 d-flex justify-content-evenly"}>
              <Link to={"/complaints/" + value}>{t("Pop.see")}</Link>
              <Receipt />
              {/*<Close/>*/}
            </div>
          );
        },
      },
    ];
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState(50);
    const [searchPhrase, setSearchPhrase] = useState('');
    const getData = () => {
        setLoading(true)
        axios.get(`/complaint?order[0][column]=3&order[0][dir]=desc${searchPhrase}`,
            {
                params: {
                    page: page,
                    limit: 50
                }
            }
        ).then(response => {
            setTotalCount(response.data.totalCount)
            setData(response.data)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }
    useEffect(() => getData(), [])
    useDidUpdateEffect(getData, [page, searchPhrase])

    const debounceTimeout = 500;
    const debounceSearch = React.useMemo(() => {
        const loadOptions = (event) => {
            setPage(1)
            const value = event.target.value;
            if (value !== "") {
                setSearchPhrase(`&filters[phrase][]=${value}`);
            } else {
                setSearchPhrase("");
            }
        };
        return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout]);

    const sortItemsByDate = (items) => {
        items.sort((a, b) => (b.createComplaintDate.substr(6, 4) + b.createComplaintDate.substr(3, 2) + b.createComplaintDate.substr(0, 2)) -
            (a.createComplaintDate.substr(6, 4) + a.createComplaintDate.substr(3, 2) + a.createComplaintDate.substr(0, 2)))

        return items;
    }


    return (
      <>
        <HeaderBar
          header={t("Reclamations.reclamations")}
          headerBarID="reclamations"
        >
          <div className="d-flex gap">
            <Form className="d-flex ms-4 position-relative search-input-mobile">
              <Form.Control
                type="search"
                placeholder={t("Pop.search")}
                className="me-2 pill search ps-5"
                aria-label="Search"
                onChange={debounceSearch}
              />
              <Button variant="link" className="btn-search ps-3">
                {" "}
                <img src="/img/lupa.svg" alt="" />
              </Button>
            </Form>

            <Button
              variant="success"
              className="fz-12"
              onClick={() => navigate("/new-complaint")}
            >
              {t("Reclamations.add")}
            </Button>
          </div>
        </HeaderBar>
        <Row>
          <Col className="gray-bg custom-scroll content-container with-header-bar">
            <PerfectScrollbar>
              {data && data.items ? (
                <div className={"shadow-radius-white p-3 mt-5"}>
                  <CustomTable
                    columns={columns}
                    data={data.items}
                    loading={loading}
                  />
                  <PaginationComponent
                    setPage={setPage}
                    page={page}
                    totalCount={totalCount}
                    limit={50}
                  />
                </div>
              ) : null}
            </PerfectScrollbar>
          </Col>
        </Row>
      </>
    );
}
export default Reclamations