import AccordionSpec from "../accordionSpec";
import React, {useEffect, useState} from "react";
import {catConfig, fullSpecification} from "./specificationConfig";
import ShortSpecification from "./shortSpecification";
import {useTranslation} from "react-i18next";


const FullSpecification = ({product}) => {
    const {t} = useTranslation();

    const productCategory = catConfig.get(product?.category?.id)
    const [utilityClasses, setUtilityClasses] = useState('')
    useEffect(() => {
        if (product?.category?.id !== 1) return
        const array = []
        product?.technicalParameters?.utilityClasses?.forEach(element => {
            array.push(element.name)
        })

        setUtilityClasses(array.join(", "))
    }, [product])

    const getValue = (item) => {
        if (item.hasOwnProperty("customValue")) {

            return item.customValue(product)
        }
        if (item.key3 !== null) {


            if (!product[item.key1]) return
            if (!product[item.key1][item.key2]) return
            if (!product[item.key1][item.key2][item.key3]) return

            return product[item.key1][item.key2][item.key3]
        }
        if (item.key2 !== null) {
            if (!product[item.key1]) return
            if (!product[item.key1][item.key2]) return

            return product[item.key1][item.key2]

        }
        if (item.key1 !== null) {

            if (!product[item.key1]) return

            return product[item.key1]

        }
    }
    return (
        <>
            {fullSpecification[productCategory]?.map((item, index) => (
                <div key={index}>
                    <div>
                        <h5>{t(item.name)}</h5>
                    </div>
                    <div className="accordion-spec-wrapper">
                        {item.fields.map((field, i) => {
                            const value = getValue(field);
                            return value ? (
                                <AccordionSpec
                                    key={i}
                                    accordionSpecType={field.label}
                                    accordionSpecData={value}
                                    accordionSpecUnit={field.unit}
                                />
                            ) : null;
                        })}
                        {utilityClasses.length ? (
                            <AccordionSpec
                                accordionSpecType={t("ProductSpec.usability")}
                                accordionSpecData={utilityClasses}
                            />
                        ) : null}
                    </div>
                </div>
            ))}
        </>
    );
}
export default FullSpecification