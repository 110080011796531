import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import LoginLeftColumn from "../components/loginLeftColumn";
import { useTranslation } from "react-i18next";
import '../i18n';
const LoginPasswordChanged = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();  
  const toLogin = () => {
    // saveProject();
    navigate("/login");
  };

  return (
    <>
      <Container fluid className="main-container login password-changed">
        <Row className="my-auto login-wrapper">
          <LoginLeftColumn />
          <Col
            xs="12"
            lg="6"
            xl="7"
            className="d-flex justify-content-center flex-column align-items-center text-center"
          >
            <div className="password-changed">
              <h2>{t("Welcome.password_changed")}</h2>
              <div className="mt-3">{t("Welcome.can_log")}</div>
              <Button
                variant="dark"
                type="submit"
                className="w-100 mt-5"
                onClick={() => {
                  toLogin();
                }}
              >
                {t("Welcome.login_again")}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default LoginPasswordChanged;
