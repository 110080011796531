const catConfig = new Map()
catConfig.set(1, 'panels');
catConfig.set(3, 'ledges');
catConfig.set(4, 'underlay');
catConfig.set(5, 'profiles');
const shortSpecConfig = {
    panels: [
        {
            key1: "color",
            key2: "number",
            key3: null,
            label: "ProductSpec.color_number"
        },
        {
            key1: "hue",
            key2: "name",
            key3: null,
            label: "ProductSpec.shade"
        },
        {
            key1: "technicalParameters",
            key2: "fugue",
            key3: null,
            label: "ProductSpec.fugue"
        },
        {
            key1: "technicalParameters",
            key2: "length",
            key3: null,
            label: "ProductSpec.length"
        },
        {
            key1: "technicalParameters",
            key2: "width",
            key3: null,
            label: "ProductSpec.width"
        },
        {
            key1: "technicalParameters",
            key2: "warrantyTime",
            key3: null,
            label: "ProductSpec.guarantee"
        },
        {
            key1: "technicalParameters",
            key2: "lockType",
            key3: null,
            label: "ProductSpec.lock_type"
        },
    ],
    profiles: [
        {
            key1: "color",
            key2: "name",
            key3: null,
            label: "ProductSpec.color"
        },
        {
            key1: "color",
            key2: "number",
            key3: null,
            label: "ProductSpec.color_number"
        },
        {
            key1: "technicalParameters",
            key2: "length",
            key3: null,
            label: "ProductSpec.length"
        },
        {
            key1: "technicalParameters",
            key2: "width",
            key3: null,
            label: "ProductSpec.width"
        },
        {
            key1: "technicalParameters",
            key2: "assemblyType",
            key3: "name",
            label: "ProductSpec.assembly_type",
            customValue: (product) => {
                return product.assemblyTypes?.find(item => item.id === product.technicalParameters.assemblyType.id)?.name
            }
        },
    ],
    ledges: [
        {
            key1: "series",
            key2: "name",
            key3: null,
            label: "ProductSpec.series"
        },
        {
            key1: "brand",
            key2: "name",
            key3: null,
            label: "ProductSpec.brand"
        },
        {
            key1: "decor",
            key2: "name",
            key3: null,
            label: "ProductSpec.decor"
        },
        {
            key1: "color",
            key2: "name",
            key3: null,
            label: "ProductSpec.color"
        },
        {
            key1: "color",
            key2: "number",
            key3: null,
            label: "ProductSpec.color_number"
        },
    ],
    underlay: [
        {
            key1: "technology",
            key2: "name",
            key3: null,
            label: "ProductSpec.technology"
        },
        {
            key1: "technicalParameters",
            key2: "certificate",
            key3: null,
            label: "ProductSpec.certificate"
        },
        {
            key1: "technicalParameters",
            key2: "floorHeating",
            key3: null,
            label: "ProductSpec.floor_heating"
        },
        {
            key1: "technicalParameters",
            key2: "thickness",
            key3: null,
            label: "ProductSpec.thickness2"
        },
        {
            key1: "technicalParameters",
            key2: "form",
            key3: null,
            label: "ProductSpec.form",
            customValue: (product) => {
                return product.forms?.find(item => item.uuid === product.technicalParameters.formUuid)?.name
            }
        },
        {
            key1: "technicalParameters",
            key2: "squareMetersInPackage",
            key3: null,
            label: "ProductSpec.square_meters_in_package"
        },
        {
            key1: "ean",
            key2: null,
            key3: null,
            label: "ProductSpec.ean"
        },

    ]
}
const fullSpecification = {
    ledges: [
        {
            name: "Config.main",
            fields: [
                {
                    key1: "category",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.category",
                },
                {
                    key1: "name",
                    key2: null,
                    key3: null,
                    label: "ProductSpec.name",
                },
                {
                    key1: "technology",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.technology",
                },
                {
                    key1: "technicalParameters",
                    key2: "height",
                    key3: null,
                    label: "ProductSpec.height",
                },
                {
                    key1: "technicalParameters",
                    key2: "dilatation",
                    key3: null,
                    label: "ProductSpec.dilatation",
                },
                {
                    key1: "technicalParameters",
                    key2: "length",
                    key3: null,
                    label: "ProductSpec.length",
                },
                {
                    key1: "ean",
                    key2: null,
                    key3: null,
                    label: "ProductSpec.ean",
                },
            ],
        },
    ],
    profiles: [
        {
            name: "Config.main",
            fields: [
                {
                    key1: "category",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.category",
                },
                {
                    key1: "name",
                    key2: null,
                    key3: null,
                    label: "ProductSpec.name",
                },
                {
                    key1: "technology",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.technology",
                },
                {
                    key1: "technicalParameters",
                    key2: "destinies",
                    key3: "name",
                    label: "ProductSpec.destinies",
                },

                {
                    key1: "maxLeveling",
                    key2: null,
                    key3: null,
                    unit: "mm",
                    label: "ProductSpec.max_leveling",
                },
                {
                    key1: "maxDilatation",
                    key2: null,
                    key3: null,
                    unit: "mm",
                    label: "ProductSpec.max_dilatation",
                },
                {
                    key1: "technicalParameters",
                    key2: "dilatation",
                    key3: null,
                    unit: "cm",
                    label: "Długości", ////////// czy to jest ptrawidłowo?
                },

                {
                    key1: "ean",
                    key2: null,
                    key3: null,
                    label: "ProductSpec.ean",
                },
            ],
        },
    ],
    underlay: [
        {
            name: "Config.param",
            fields: [
                {
                    key1: "technicalParameters",
                    key2: "assemblyType",
                    key3: "name",
                    label: "ProductSpec.assembly_type",
                    customValue: (product) => {
                        return product.assemblyTypes?.find(item => item.id === product.technicalParameters.assemblyType.id)?.name
                    }
                },
                {
                    key1: "technicalParameters",
                    key2: "fireResistance",
                    key3: null,
                    label: "ProductSpec.fire_resistance",
                },
                {
                    key1: "technicalParameters",
                    key2: "thermalResistance", // poszukać czy to na pewno to
                    key3: null,
                    unit: "m2K/W",
                    label: "ProductSpec.low_thermal_resistance",
                },
                {
                    key1: "technicalParameters",
                    key2: "fireResistance",
                    key3: null,
                    label: "ProductSpec.floor_heating",
                },
                {
                    key1: "technicalParameters",
                    key2: "reflectedSoundsSuppressionLabel",
                    key3: null,
                    unit: "%",
                    label: "ProductSpec.reflected_sounds_suppression_label",
                },
                {
                    key1: "technicalParameters",
                    key2: "soundsSuppressionLabel",
                    key3: null,
                    unit: "kPA",
                    label: "ProductSpec.sounds_suppression_label",
                },
                {
                    key1: "technicalParameters",
                    key2: "staticLoad",
                    key3: null,
                    label: "ProductSpec.static_load",
                },
                {
                    key1: "technicalParameters",
                    key2: "staticLoadClass",
                    key3: null,
                    label: "ProductSpec.static_load_class",
                },
                {
                    key1: "technicalParameters",
                    key2: "dynamicLoadLabel",
                    key3: null,
                    unit: "ProductSpec.cycle", // poprawić na różnych językach - po id jednoski może się da
                    label: "ProductSpec.dynamic_load_label",
                },
                {
                    key1: "technicalParameters",
                    key2: "dynamicLoadClass",
                    key3: null,
                    label: "ProductSpec.dynamic_load_class",
                },
                {
                    key1: "technicalParameters",
                    key2: "inequalityLabel",
                    key3: null,
                    unit: "mm",
                    label: "ProductSpec.inequality_label",
                },
                {
                    key1: "technicalParameters",
                    key2: "vaporPermeability",
                    key3: null,
                    unit: "m",
                    label: "ProductSpec.vapor_permeability",
                },
                {
                    key1: "technicalParameters",
                    key2: "impactResistance",
                    key3: null,
                    unit: "mm",
                    label: "ProductSpec.impact_resistance",
                },
                {
                    key1: "technicalParameters",
                    key2: "impactResistanceClass",
                    key3: null,
                    unit: null,
                    label: "ProductSpec.impact_resistance_class",
                },
                {
                    key1: "technicalParameters",
                    key2: "wasteKey",
                    key3: null,
                    unit: null,
                    label: "ProductSpec.waste_key",
                },
                {
                    key1: "technicalParameters",
                    key2: "certificate",
                    key3: null,
                    unit: null,
                    label: "ProductSpec.certificate",
                },
                {
                    key1: "technicalParameters",
                    key2: "ean",
                    key3: null,
                    unit: null,
                    label: "ProductSpec.ean",
                },
            ],
        },
    ],
    panels: [
        {
            name: "Config.main",
            fields: [
                {
                    key1: "name",
                    key2: null,
                    key3: null,
                    label: "ProductSpec.name",
                },
                {
                    key1: "brand",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.brand",
                },
                {
                    key1: "decor",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.decor",
                },
                {
                    key1: "series",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.series",
                },
                {
                    key1: "category",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.category",
                },
                {
                    key1: "technology",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.technology",
                },
                {
                    key1: "technicalParameters",
                    key2: "form",
                    key3: null,
                    label: "ProductSpec.form",
                    customValue: (product) => {
                        return product.forms?.find(item => item.uuid === product.technicalParameters.formUuid)?.name
                    }
                },
            ],
        },
        {
            name: "Config.logistic",
            fields: [
                {
                    key1: "technicalParameters",
                    key2: "length",
                    key3: null,
                    unit: "mm",
                    label: "ProductSpec.length_panel",
                },
                {
                    key1: "technicalParameters",
                    key2: "width",
                    key3: null,
                    unit: "mm",
                    label: "ProductSpec.width_panel",
                },
                {
                    key1: "technicalParameters",
                    key2: "piecesInPackage",
                    key3: null,
                    label: "ProductSpec.pieces_in_package",
                },
                {
                    key1: "technicalParameters",
                    key2: "squareMetersInPackage",
                    key3: null,
                    label: "ProductSpec.square_meters_in_package",
                },
            ],
        },
        {
            name: "Config.param",
            fields: [
                {
                    key1: "technicalParameters",
                    key2: "dimensionStability",
                    key3: null,
                    label: "ProductSpec.dimensional_stability",
                },
                {
                    key1: "technicalParameters",
                    key2: "chairTest",
                    key3: null,
                    label: "ProductSpec.chair_test",
                },
                {
                    key1: "technicalParameters",
                    key2: "lockStrength",
                    key3: null,
                    unit: "kN/m",
                    label: "ProductSpec.lock_strength",
                },
                {
                    key1: "technicalParameters",
                    key2: "antistatic",
                    key3: null,
                    unit: "kV",
                    label: "ProductSpec.antistaticity",
                },
                {
                    key1: "technicalParameters",
                    key2: "thermalResistance",
                    key3: null,
                    label: "ProductSpec.thermal_resistance",
                },
                {
                    key1: "technicalParameters",
                    key2: "floorHeating",
                    key3: null,
                    label: "ProductSpec.floor_heating",
                },
                {
                    key1: "technology",
                    key2: "name",
                    key3: null,
                    label: "ProductSpec.technology",
                },
                {
                    key1: "technicalParameters",
                    key2: "thickness",
                    key3: null,
                    label: "ProductSpec.thickness",
                },
                {
                    key1: "technicalParameters",
                    key2: "topLayer",
                    key3: null,
                },
                {
                    key1: "technicalParameters",
                    key2: "assemblyType",
                    key3: "name",
                    label: "ProductSpec.assembly_type",
                    customValue: (product) => {
                        return product.assemblyTypes?.find(item => item.id === product.technicalParameters.assemblyType.id)?.name
                    }
                },
                {
                    key1: "technicalParameters",
                    key2: "fireResistance",
                    key3: null,
                    label: "ProductSpec.fire_resistance",
                },
                {
                    key1: "technicalParameters",
                    key2: "soundsSuppression",
                    key3: null,
                    unit: "dB",
                    label: "ProductSpec.sounds_suppression",
                },
                {
                    key1: "technicalParameters",
                    key2: "soundsSuppressionName",
                    key3: null,
                    label: "ProductSpec.sounds_suppression_name",
                },
                {
                    key1: "technicalParameters",
                    key2: "soundsSuppression2",
                    key3: null,
                    label: "ProductSpec.sounds_suppression2",
                },
                {
                    key1: "technicalParameters",
                    key2: "soundsSuppression2Name",
                    key3: null,
                    label: "ProductSpec.sounds_suppression2_name",
                },
                {
                    key1: "technicalParameters",
                    key2: "emissivity",
                    key3: null,
                    label: "ProductSpec.emissivity",
                },
                {
                    key1: "technicalParameters",
                    key2: "fugue",
                    key3: null,
                    label: "ProductSpec.fugue",
                },
                {
                    key1: "technicalParameters",
                    key2: "lockType",
                    key3: null,
                    label: "ProductSpec.lock_type",
                },
                {
                    key1: "technicalParameters",
                    key2: "voc",
                    key3: null,
                    label: "ProductSpec.voc",
                },
                {
                    key1: "technicalParameters",
                    key2: "ean",
                    key3: null,
                    label: "EAN",
                },
                {
                    key1: "technicalParameters",
                    key2: "ean",
                    key3: null,
                    label: "EAN",
                },
                {
                    key1: "technicalParameters",
                    key2: "dop",
                    key3: null,
                    label: "DOP",
                },
                {
                    key1: "technicalParameters",
                    key2: "ce",
                    key3: null,
                    label: "CE",
                },
            ],
        },
    ],
};
export {shortSpecConfig, catConfig, fullSpecification}