import React, {useState} from "react";
import {Alert, Col, Container, Row, Spinner} from "react-bootstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import LoginLeftColumn from "../components/loginLeftColumn";
import {useForm} from "react-hook-form";
import axios from "axios";
import {useDispatch} from "react-redux";
import { getUserData, setToken } from "../redux/userSlice";
import { useTranslation } from "react-i18next";


const Login = () => {
const { t } = useTranslation();  

    const navigate = useNavigate();
    const {register, handleSubmit} = useForm();
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState("")
    const dispatch = useDispatch()
    let location = useLocation();
    const onSubmit = data => {
        delete axios.defaults.headers.common["Authorization"];
        let from = location.state?.from?.pathname || "/home";
        setLoading(true)
        axios.post(
            "/user/login",
            JSON.stringify(data),
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        ).then(response => {
            if (response.status === 200) {
                const data = response.data
                localStorage.setItem("token", data.token);
                localStorage.setItem("refreshToken", data.refreshToken);
                axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

                dispatch(setToken(data.token));
                dispatch(getUserData());
                navigate(from, {replace: true});
            }
            setLoading(false)
        }).catch(error => {
            console.log(error.response.data.message)
            setMessage(error.response.data.message)
            setShowAlert(true)
            setLoading(false)
            console.log(error)
        })
    };


    return (
        <>
            <Container fluid className="main-container login">
                <Row className="my-auto login-wrapper">
                    <LoginLeftColumn/>
                    <Col
                        xs="12"
                        lg="6"
                        xl="7"
                        className="d-flex justify-content-center flex-column align-items-center text-center"
                    >
                        <div>
                            <h2>{t("Welcome.login_heading")} <br/> {t("Welcome.login_heading2")}</h2>
                            <Form
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Form.Group className="mt-5 mb-4 pb-1 user login" controlId="user-login">
                                    <Form.Control
                                        type="text"
                                        placeholder={t("Welcome.login_placeholder")}
                                        className=""
                                        required
                                        {...register("username")}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-5 mt-4 user password" controlId="user-password">
                                    <Form.Control
                                        type="password"
                                        placeholder={t("Welcome.pass_placeholder")}
                                        {...register("password")}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100 mb-3 mt-1 border-0"
                                        disabled={loading}>
                                    {loading ? <Spinner size={"sm"} animation="border"/> : t("Welcome.login_heading")}
                                </Button>
                                <Alert

                                    variant={"danger"}
                                    show={showAlert}
                                >
                                    {message}
                                </Alert>
                            </Form>
                            <Link to="/login-reset" role="button" className="fz-12">
                            {t("Welcome.password_reminder")}
                            </Link>
                            <div className="mt-1 text-grey fz-12">
                            {t("Welcome.procedure")} <Link>{t("Welcome.procedure2")}</Link>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default Login;
