import React from "react";
import {Col} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import '../i18n';

const LoginLeftColumn = () => {
  /*const navigate = useNavigate();*/
  const { t } = useTranslation();
  return (
      <Col
          xs="12"
          lg="6"
          xl="5"
          className="d-flex justify-content-center flex-column"
      >
        <h1>{ window.location.host === 'b2bewifoam.de' ? t('Welcome.ewifoamHeading') : t("Welcome.heading")}</h1>
        <h3>{t("Welcome.subheading")}</h3>
      </Col>
  );
};
export default LoginLeftColumn;
