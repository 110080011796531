import {Badge} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


function CustomTableDescription({ item, minOrder, style, innerref, className, }) {
const { t } = useTranslation();  
    
    const image = ()=> {
        if(!item?.product?.images){
            return ""
        }
        const newImages = [
            ...item?.product?.images.filter(item => item.category === "Packshot"),
            ...item?.product?.images.filter(item => item.category !== "Packshot")
        ]
        return `${newImages[0].resized ? newImages[0]?.resized?.list?.default : newImages[0]?.default}`
}


    return (
      <div
        className={"custom-table-description " + className ?? ""}
        style={style}
        ref={innerref}
      >
        <div className="image">
          <div className="relative-padding">
            {item?.product.id ? (
              <Link to={"/product/" + item?.product.id}>
                <img
                  //style do przeniesienia do scss
                  style={{ objectFit: "cover" }}
                  src={image()}
                  alt=""
                />
              </Link>
            ) : (
              <img
                //style do przeniesienia do scss
                style={{ objectFit: "cover" }}
                src={image()}
                alt=""
              />
            )}
          </div>
        </div>
        <div>
          <div className="type d-lg-none">{item?.product.type}</div>
          {item?.product.id ? (
            <Link to={"/product/" + item?.product.id}>
              <h3 className="h3">
                {item?.product.name}
                <Badge
                  pill
                  bg="dark"
                  className="fz-12 fw-bold smaller-badge ms-1"
                >
                  {item?.product?.color?.number}
                </Badge>
              </h3>
            </Link>
          ) : (
            <h3 className="h3">
              {item?.product.name}
              <Badge pill bg="dark" className="fz-12 fw-bold smaller-badge">
                {item?.product?.color?.number}
              </Badge>
            </h3>
          )}

          <div className="parameters">
            {item?.product?.ean ? (
              <div>
                <b>EAN:</b> {item?.product?.ean}
              </div>
            ) : null}
            {item?.product?.brand?.name ? (
              <div>
                <b>{t("ProductPage.brand")}:</b> {item?.product?.brand?.name}
              </div>
            ) : null}
            {item.isFromOffer ? null : (
              <div>
                <b>{t("List.min_order")}: </b>
                {minOrder}
              </div>
            )}
          </div>
        </div>
      </div>
    );
}

export default CustomTableDescription;
