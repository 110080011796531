import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../components/navbars/navbar";
import Footer from "../components/footer";

const LoginLayout = () => {
  return (
    <>
      <Navigation />
      <Outlet />
      <Footer />
    </>
  );
};

export default LoginLayout;
