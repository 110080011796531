

const currencyFormatter = (value, canSeePrices) => {

    const v = isNaN(value ) ? 0 : value ?? 0
    let parts = v.toFixed(2).split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    if(canSeePrices) {
        return parts.join(".")
    } else{
        return "-"
    }

};

export {currencyFormatter};


