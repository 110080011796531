import Button from "react-bootstrap/Button";
import { Alert, Col, ProgressBar, Row, Spinner } from "react-bootstrap";
import HeaderBar from "../components/headerBar/headerBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Download } from "../svg/download.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import SummaryTable from "../components/summaryTable/summaryTable";
import xlsxExporter from "../helpers/xlsxExporter";
import handleOrderXlsx from "../components/order/handleOrderXlsx";
import getFile from "../components/order/getOrderFile";
import { currencyFormatter } from "../helpers/currencyTools";
import handleCartOffers from "../helpers/handleCartOffers";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../redux/userSlice";
import { addToCart } from "../redux/cartSlice";
import { setOrderNumber, setProducts } from "../redux/reclamationSlice";
import { useTranslation } from "react-i18next";

const OrderDetails = () => {
  const { t } = useTranslation();

  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderNumber, orderId } = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [minLogistic, setMinLogistic] = useState();
  const [spreadSheetData, setSpreadSheetData] = useState();
  const [progress, setProgress] = useState(0);
  const showDelivered = data?.orderProducts.some(
    (item) =>
      item.orderOrderProduct.quantity !== item.deliveryOrderProduct.quantity
  );
  const [processing, setProcessing] = useState(false);
  const [complaintOrderNumber, setComplaintOrderNumber] = useState(orderNumber);

  function postShow(id) {
    axios.post(`/order/${id}/show`).then(() => {
      dispatch(getUserData());
    });
  }
  const getData = () => {
    axios
      .get(orderId ? `/order/${orderId}` : `/order/number/${orderNumber}`)
      .then((response) => {
        setData(response.data);
        setComplaintOrderNumber(response.data.sapOrderNumber);
        setSpreadSheetData(
          handleOrderXlsx(
            response.data,
            userData?.customerData?.canSeeProductPrice
          )
        );

        setProgress(() => {
          switch (response.data.status) {
            case "EC00":
              postShow(response.data.id);
              return 0;
            case "EC01":
              return 15;
            case "EC02":
              return 50;
            case "EC03":
              return 100;
          }
        });
      });
    axios.get("/minima-logistic/details").then((response) => {
      setMinLogistic(response.data.minimaLogistic);
    });
  };

  useEffect(getData, []);
  return data ? (
    <>
      <HeaderBar
        header={`${t("Orders.details")} ${
          data.clientOrderNumber
            ? `${data.clientOrderNumber === "" ? "" : data.clientOrderNumber}`
            : `${data.sapOrderNumber === "" ? "" : data.sapOrderNumber}`
        }`}
        headerBarID="order-details"
        className="header-page-1386 flex-column flex-md-row"
      >
        <div className="d-flex gap py-3">
          {progress === 0 ? (
            <Button
              disabled={processing}
              variant="success"
              className="fz-12"
              onClick={() => {
                setProcessing(true);
                axios
                  .get(`/order/${data.id}/confirm`)
                  .then((resposne) => {
                    navigate("/orders");
                    setProcessing(false);
                  })
                  .catch((error) => {
                    navigate("/orders");
                    setProcessing(false);
                  });
              }}
            >
              {processing ? (
                <Spinner animation={"border"} size={"sm"} />
              ) : (
                t("Orders.accept")
              )}
            </Button>
          ) : data.orderProducts.some(
              (product) => product.isFromOffer
            ) ? null : (
            <Button
              variant="success"
              className="fz-12"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                data.orderProducts.forEach((item) => {
                  dispatch(
                    addToCart({
                      unit: item.orderOrderProduct.unit,
                      selectedUnit: item.orderOrderProduct.unit,
                      quantity: item.orderOrderProduct.quantity,
                      itemData: {
                        sapIndex: item.product.sapIndex,
                      },
                    })
                  ).then(() => {
                    setLoading(false);
                    setShowAlert(true);
                    setTimeout(() => {
                      setShowAlert(false);
                    }, 5000);
                  });
                });
              }}
            >
              {loading ? (
                <Spinner animation={"border"} size={"sm"} />
              ) : (
                t("Orders.again")
              )}
            </Button>
          )}
          {progress === 100 && complaintOrderNumber ? (
            <Button
              variant="danger"
              className="fz-12"
              onClick={() => {
                dispatch(setOrderNumber(complaintOrderNumber));
                dispatch(setProducts(data?.orderProducts));
                navigate("/new-complaint");
              }}
            >
              {t("Orders.complaint")}
            </Button>
          ) : null}
        </div>
      </HeaderBar>

      <Row>
        <Col className="gray-bg custom-scroll content-container with-header-bar  ">
          <Alert show={showAlert} variant={"success"}>
            {t("Orders.cart_added")}
          </Alert>
          <PerfectScrollbar>
            <div className="max-1386 pb-5 pt-4">
              {data.status === 'EC04' ?
                  <Alert variant={'danger'}>
                    {data["status" + data.status + "Name"]}
                  </Alert>

                  :progress > 0 ? (
                <div className=" shadow-radius-white py-4">
                  <div
                    className={"  position-relative"}
                    style={{ padding: "9px 0" }}
                  >
                    <ProgressBar
                      now={progress}
                      variant={"info"}
                      style={{ height: "2px" }}
                    />
                    <div
                      className={`status-marker status-marker--1 ${
                        progress >= 15 ? "active" : ""
                      }`}
                    ></div>
                    <div
                      className={`status-marker status-marker--2 ${
                        progress >= 50 ? "active" : ""
                      }`}
                    ></div>
                    <div
                      className={`status-marker status-marker--3 ${
                        progress >= 85 ? "active" : ""
                      }`}
                    ></div>
                  </div>

                  <div
                    className={"position-relative d-none d-sm-block"}
                    style={{ paddingTop: "27px", paddingBottom: "37px" }}
                  >
                    <div className="status-item status-item--1">
                      <div className="status-name fz-10 fw-bold text-uppercase text-center">
                        {data?.statusEC01Name}
                      </div>
                    </div>
                    <div className="status-item status-item--2">
                      <div className="status-name fz-10 fw-bold text-uppercase text-center">
                        {data?.statusEC02Name}
                      </div>
                    </div>
                    <div className="status-item status-item--3">
                      <div className="status-name fz-10 fw-bold text-uppercase text-center">
                        {data?.statusEC03Name}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {data.transportInfo?.transportLink ? (
                <a
                  href={data.transportInfo?.transportLink}
                  target={"_blank"}
                  className={"mt-3 mb-40 mx-lg-0 d-block"}
                  style={{ width: "fit-content" }}
                >
                  <Button variant="dark">{t("Orders.track_shipment")}</Button>
                </a>
              ) : null}

              <div className="d-flex gap mt-4 pt-3 flex-wrap cards-wrapper">
                <div className="flex-grow-1">
                  <div className="shadow-radius-white p-30 fz-12 text-uppercase">
                    <div className="fw-bold">{t("Orders.order_stat")}</div>
                    <div className="d-flex align-items-center my-2">
                      <div
                        className={
                          "circle  small me-1 " +
                          (data.status === 'EC04' ? 'danger' : progress >= 85 ? "success" : "warning")
                        }
                      ></div>
                      <div>{data["status" + data.status + "Name"]}</div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-1">
                        {" "}
                        {t("Orders.order_date")}
                      </div>
                      <div>{data?.createdOrderDate}</div>
                    </div>
                    <div className="d-flex">

                      <div className="fw-bold me-1">{t("Orders.delivery_date")}:</div>
                      <div>{data?.deliveryDate?.split(" ")[0]}</div>
                    </div>

                    <div className="d-flex">
                      <div className="fw-bold me-1"> {t("Orders.stat")}:</div>
                      <div>
                        {currencyFormatter(
                          data.netOrderValue - data.netShipCost,
                          userData.customerData.canSeeProductPrice
                        )}{" "}
                        {data.currency}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-1">{t("Orders.cost")}</div>
                      <div>
                        {currencyFormatter(
                          data?.netShipCost,
                          userData.customerData.canSeeProductPrice
                        )}{" "}
                        {data.currency}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-1">
                        {t("Orders.order_value")}
                      </div>
                      <div>
                        {currencyFormatter(
                          data?.netOrderValue,
                          userData.customerData.canSeeProductPrice
                        )}{" "}
                        {data.currency}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-1">
                        {t("Orders.tax")} ({data.taxValue}%):
                      </div>
                      <div>
                        {currencyFormatter(
                          data.taxOrderValue,
                          userData.customerData.canSeeProductPrice
                        )}{" "}
                        {data.currency}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-1">{t("Orders.tax")}</div>
                      <div>
                        {currencyFormatter(
                          data.grossOrderValue,
                          userData.customerData.canSeeProductPrice
                        )}{" "}
                        {data.currency}
                      </div>
                    </div>
                  </div>
                  <Button
                    variant="outline-dark"
                    className="mt-3"
                    onClick={() =>
                      xlsxExporter(
                        spreadSheetData,
                        `${t("Reclamations.order_nr2")} ${
                          data.clientOrderNumber
                            ? data.clientOrderNumber
                            : data.sapOrderNumber
                        }`,
                        userData.customerData.canSeeProductPrice
                      )
                    }
                  >
                    {t("Orders.generate")} XLSX
                  </Button>
                </div>
                {userData.customerData.accessToInvoice ? (
                  <div className="w-290">
                    <div className="shadow-radius-white p-30">
                      <div className="fz-18 fw-500 mb-3">
                        {t("Orders.docs")}
                      </div>
                      {progress > 0 ? (
                        <Button
                          variant={"link"}
                          className="d-flex align-items-center my-1 "
                          onClick={() =>
                            getFile(
                              {
                                docNumber: data.sapOrderNumber,
                                docType: "VBAK",
                              },
                              `${t("Orders.proform")} ${
                                data.clientOrderNumber
                                  ? data.clientOrderNumber
                                  : data.sapOrderNumber
                              }`
                            )
                          }
                        >
                          <Download className="icon-action svg-40 me-1" />
                          {t("Orders.proform")}

                          {/*    deliveryNumber*/}
                        </Button>
                      ) : null}

                      {data.invoiceNumber ? (
                        <Button
                          variant={"link"}
                          className="d-flex align-items-center my-1 "
                          onClick={() =>
                            getFile(
                              {
                                docNumber: data.invoiceNumber,
                                docType: "VBRK",
                              },
                              `${t("Pop.invoice_vat")} ${
                                data.clientOrderNumber
                                  ? data.clientOrderNumber
                                  : data.sapOrderNumber
                              }`
                            )
                          }
                        >
                          <Download className="icon-action svg-40 me-1" />
                          {t("Orders.vat")}
                          {/*//invocieNumber*/}
                        </Button>
                      ) : null}
                      {data.deliveryNumber && progress >= 85 ? (

                          <Button
                              variant={"link"}
                              className="d-flex align-items-center my-1 "
                              onClick={() =>
                                  getFile(
                                      {
                                        docNumber: data.deliveryNumber,
                                        docType: "LIKP",
                                      },
                                      `${t("Pop.wz")} ${
                                          data.clientOrderNumber
                                              ? data.clientOrderNumber
                                              : data.sapOrderNumber
                                      }`
                                  )
                              }
                          >
                            <Download className="icon-action svg-40 me-1"/>
                            {t("Pop.wz")}
                            {/*//transportNumber*/}
                          </Button>

                      ) : null}
                    </div>
                  </div>
                ) : (
                  <></>
                )}


                <div className="w-290">
                  <div className="shadow-radius-white p-30">
                    {t("Cashbox.adress")}
                    <div className="fz-18 fw-500 mb-3"></div>
                    <div className="fz-12">
                      <div className="fw-bold">{`${data.shipTo?.firstName} ${data.shipTo?.lastName}`}</div>
                      <div>ul. {data.shipTo?.address}</div>
                      <div>{`${data.shipTo?.postCode} ${data.shipTo?.city}`}</div>
                      <div>{data.shipTo?.country}</div>
                    </div>
                  </div>
                </div>


                <div className="w-290">
                  <div className="shadow-radius-white p-30">
                    {data.comment ? (
                      <>
                        <div className="fz-18 fw-500 ">{t("Pop.comment")}:</div>
                        <div className="fz-12 mb-3">
                          <div>{data.comment}</div>
                        </div>
                      </>
                    ) : null}
                    <div className="fz-18 fw-500 ">{t("Orders.order_created")}:</div>
                    <div className="fz-12">
                      <div>
                        {data.isCreatedByCustomer
                          ? t("Orders.client")
                          : t("Orders.decora_employee")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-content with-header mt-5">
                <div>
                  <h2>{t("Cashbox.order_summary")}</h2>
                </div>
                {data?.orderProducts.length ? (
                  <SummaryTable
                    items={data?.orderProducts
                      .filter((element) => !element.isFromOffer)
                      .map((item) => ({
                        ...item,
                        mainUnitPriceNet: item.orderOrderProduct.netUnitPrice,
                        currency: data.currency,
                        valueNet: item.orderOrderProduct.netPriceValue,
                        quantity: item.orderOrderProduct.quantity,
                        unit: item.orderOrderProduct.shortUnitName,
                      }))}
                    offers={handleCartOffers(data?.orderProducts).map((offer) =>
                      offer.map((item) => ({
                        ...item,
                        mainUnitPriceNet: item.orderOrderProduct.netUnitPrice,
                        currency: data.currency,
                        valueNet: item.orderOrderProduct.netPriceValue,
                        quantity: item.orderOrderProduct.quantity,
                        unit: item.orderOrderProduct.shortUnitName,
                      }))
                    )}
                    currency={data.currency}
                    minLogistic={minLogistic}
                    netShipping={data.netShipCost}
                    taxValue={data.taxValue}
                    grossWholeOrderValue={data.grossOrderValue}
                    netWholeOrderValue={data.netOrderValue}
                    taxWholeOrderValue={data.taxOrderValue}
                    productOrderNumber={true}

                  />
                ) : null}
              </div>
              {showDelivered && progress >= 85 ? (
                <div className="main-content with-header mt-5">
                  <div>
                    <h2>{t("Orders.delivered")}</h2>
                  </div>
                  <SummaryTable
                    items={data?.orderProducts.map((item) => ({
                      ...item,
                      mainUnitPriceNet: item.deliveryOrderProduct.netUnitPrice,
                      currency: data.currency,
                      valueNet: item.deliveryOrderProduct.netPriceValue,
                      quantity: item.deliveryOrderProduct.quantity,
                      unit: item.deliveryOrderProduct.shortUnitName,
                    }))}
                    currency={data.currency}
                    minLogistic={minLogistic}
                    netShipping={data.netShipCost}
                    taxValue={data.taxValue}
                    grossWholeOrderValue={data.deliverySummary.grossOrderValue}
                    netWholeOrderValue={data.deliverySummary.netOrderValue}
                    taxWholeOrderValue={data.deliverySummary.taxOrderValue}
                    productOrderNumber={true}
                  />
                </div>
              ) : null}
            </div>
          </PerfectScrollbar>
        </Col>
      </Row>
    </>
  ) : (
    <div className={"w-100 d-flex justify-content-center "}>
      <Spinner animation={"border"} />
    </div>
  );
};
export default OrderDetails;
