import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./transaltions/en/translation.json"
import pl from "./transaltions/pl/translation.json"
import ru from "./transaltions/ru/translation.json"
import cz from "./transaltions/cz/translation.json"
import es from "./transaltions/es/translation.json"
import sk from "./transaltions/sk/translation.json"
import nl from "./transaltions/nl/translation.json"
import sv from "./transaltions/sv/translation.json"
import de from "./transaltions/de/translation.json"
import fr from "./transaltions/fr/translation.json"

const resources = {
    pl: {
        translation: pl
    }, en: {
        translation: en
    }, ru: {
        translation: ru
    }, cz: {
        translation: cz
    }, es: {
        translation: es
    }, sk: {
        translation: sk
    }, nl: {
        translation: nl
    }, sv: {
        translation: sv
    }, de: {
        translation: de
    }, fr: {
        translation: fr
    },

};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        cleanCode: true,
        supportedLngs: ["pl", "en", "ru", 'cz', 'es', 'sk', 'nl', 'sv', 'de', 'fr'],
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n