import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import cartReducer from './cartSlice'
import toastReducer from './toastSlice'
import offerReducer from './offerSlice'
import reclamationReducer from './reclamationSlice'
import marketListReducer from './marketingListSlice'
export default configureStore({
    reducer: {
        user: userReducer,
        cart: cartReducer,
        toast: toastReducer,
        offer: offerReducer,
        reclamation: reclamationReducer,
        marketingList: marketListReducer,

    }
})