// import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import {useDispatch} from "react-redux";
import {addToCart} from "../../redux/cartSlice";
import {useState} from "react";
import {FormSelect, Spinner} from "react-bootstrap";
import {ReactComponent as Done} from "../../svg/done_black_24dp (5).svg";


function AddToCart({
                       className = "",
                       buttonText = "Dodaj",
                       productSumValue = "",
                       netto = "",
                       item,
                       quantity,
                       unit,
                       setQuantity,
                       onAddingComplete = () => console.log("added"),
                       selectedUnit,
                       priceLoading
                   }) {

    const [loading, setLoading] = useState(false)
    const [showMark, setShowMark] = useState(false)
    const dispatch = useDispatch()
    const onCLick = () => {
        setLoading(true)
        dispatch(addToCart({
            itemData: item,
            quantity: quantity,
            unit: unit,
            selectedUnit: selectedUnit
        })).then(response => {
            setQuantity(1)

            setLoading(false)
            setShowMark(true)
            onAddingComplete()
            setTimeout(() => {
                setShowMark(false)

            }, 500)
        })
    }
    return (
        <>
            <Button variant="success" onClick={onCLick} className={`${className}`} disabled={loading || priceLoading}>
                {
                    loading || priceLoading ?
                        <Spinner animation={"border"} size={"sm"}/>
                        : showMark ?
                            <Done/>
                            :
                            <>
                                {buttonText} {productSumValue} {netto}
                            </>
                }
            </Button>{' '}
        </>
    );
};
export default AddToCart;
