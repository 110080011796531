import Counter from "../product/counter";
import UnitSelect from "../product/unitSelect";
import { Link } from "react-router-dom";
import { React, useEffect, useState } from "react";
import AddToCart from "../product/btnAddToCart";
import priceAndUnit from "../../helpers/priceAndUnit";
import checkAvailability from "../../helpers/checkAvailability";
import {Badge, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import CustomSelect from "../customSelect/customSelect";
import { currencyFormatter } from "../../helpers/currencyTools";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";

const TabelProduct = ({ className = "", item, isActive, toggleClass, priceLoading }) => {
  const [unit, setUnit] = useState(
    item.availableUnits ? item.availableUnits[0]?.unit : ""
  );
  const { availableUnits } = item;
  const [quantity, setQuantity] = useState(1);
  const [cartReadyData, setCartReadyData] = useState(null);
  useEffect(() => {
    setCartReadyData(priceAndUnit(item, quantity, unit));
  }, [item, quantity, unit]);
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [availabilities, setAvailabilities] = useState(null);
  const userData = useSelector((state) => state.user.data);
  const { t } = useTranslation();
    const [showCartBadge, setShowCartBadge] = useState(false);

  return (
    <tr className={`${className}`}>
      <td>
        <Link to={"/product/" + item.id}>{item.name}</Link>
      </td>
      <td className="h-100">
        <div className="position-relative table-relative-wrapper">
          <span>{item.brand?.name}</span>{" "}
          {item.isDedicatedProducts ? (
            <div
              className="uppercase text-center fw-bold fz-9 show-accesories-wrapper"
              onClick={toggleClass}
            >
              <span
                className={`arrow-left rotate show-accesories table-accesories ${
                  isActive ? "opened" : "closed"
                }`}
              >
                {isActive ? t("List.collapse") : t("List.expand")}
              </span>
            </div>
          ) : null}
        </div>
      </td>
      <td>{item.series?.name}</td>
      <td>{item.color?.number}</td>
      <td>{item?.ean}</td>
      <td>
        {" "}
        {priceLoading ? <Spinner animation={'border'} size={'sm'}/> : `${
          userData.customerData.canSeeProductPrice
            ? item?.priceList?.minPrice === item?.priceList?.maxPrice
              ? item?.priceList?.minPrice.toFixed(2)
              : item?.priceList?.minPrice.toFixed(2) +
                "-" +
                item?.priceList?.maxPrice.toFixed(2)
            : "-"
        } ${item?.priceList?.currency} / ${
          item?.priceList?.conditionUnitTrans
        }`}
      </td>
      <td>
        <Counter
          className="mx-auto"
          setQuantity={setQuantity}
          quantity={quantity}
        />
      </td>
      <td className="text-uppercase">
        {/*<UnitSelect value={unit} data={availableUnits} onSelect={(v) => setUnit(v.target.value)}/>*/}
        <CustomSelect
          options={availableUnits}
          value={unit}
          onChange={setUnit}
        />
      </td>
      <td>
        <Button variant={"link"}
          onClick={() => {
            setLoadingAvailability(true);

            checkAvailability(
              item,

              cartReadyData?.baseQuantity, cartReadyData?.baseUnit?.unit
            ).then((response) => {
              setAvailabilities(response.data.items[0].availabilities);
              setLoadingAvailability(false);
            });
          }}
        >
          {t("List.availability")}
        </Button>
        <div className={"d-flex flex-column align-items-center"}>
          {loadingAvailability ? (
            <Spinner animation={"border"} size={"sm"} />
          ) : availabilities ? (
            availabilities?.map((element, index) => (
              <div
                key={index}
                className={`product-availability--${
                  element.status === "API.CART.AVAILABLE_NOW"
                    ? "now"
                    : element.status === "API.CART.AVAILABLE_FROM"
                    ? "soon"
                    : element.status === "API.CART.NOT_AVAILABLE"
                    ? "never"
                    : ""
                }`}
              >
                {element.statusName}: {element.quantity.toFixed(3)}{" "}
                {element.unit}
              </div>
            ))
          ) : null}
        </div>
      </td>
      <td>

          {item.isInCart || showCartBadge ?
              <div className="d-flex w-100 justify-content-end">
                  <OverlayTrigger
                      overlay={
                          <Tooltip id="button-tooltip">
                              {t("List.cart_badge")}
                          </Tooltip>
                      }
                  >
                      <Badge className={"cart-badge"} pill bg={"primary"}>!</Badge>
                  </OverlayTrigger>
              </div>   : null}
          {
              priceLoading ? <Spinner animation={'border'} /> :         <AddToCart
                  buttonText={t("Pop.add")}
                  item={item}
                  quantity={cartReadyData?.cartQuantity}
                  setQuantity={(val) => {
                      setQuantity(val);
                  }}
                  onAddingComplete={()=>setShowCartBadge(true)}
                  unit={cartReadyData?.defaultUnit?.unit}
                  selectedUnit={unit}
                  productSumValue={`${currencyFormatter(
                      cartReadyData?.cartQuantity * item?.priceList?.minPrice,
                      userData.customerData.canSeeProductPrice
                  )} ${item?.priceList?.currency}`}
                  className="fz-10 mb-1"
              />
          }

        <div className="fz-9 text-second-black fw-bold">
          {cartReadyData?.cartQuantity} {cartReadyData?.defaultUnit?.unitName}
          {cartReadyData?.defaultUnit?.unit !==
          cartReadyData?.baseUnit?.unit ? (
            <>
              = {cartReadyData?.baseQuantity} {cartReadyData?.baseUnit.unitName}
            </>
          ) : null}
        </div>
      </td>
    </tr>
  );
};
export default TabelProduct;