import Button from "react-bootstrap/Button";
import {Row, Col, Popover, OverlayTrigger, Overlay, Spinner} from "react-bootstrap";
import HeaderBar from "../components/headerBar/headerBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import RowEqual from "../components/customTable/RowEqual";
import {Link} from "react-router-dom";
import CustomTable from "../components/table/customTable";
import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as Eye} from "../svg/eye-icon.svg";
import {ReactComponent as Dots} from "../svg/vertical-dots.svg";
import {ReactComponent as Sort} from "../svg/sort-icon.svg";
import useDidUpdateEffect from "../customHooks/useDidUpdateEffect";
import PaginationComponent from "../components/pagination";
import useOutsideClick from "../customHooks/useOutsideClick";
import getFile from "../components/order/getOrderFile";
import {ReactComponent as Download} from "../svg/download.svg";
import {currencyFormatter} from "../helpers/currencyTools";
import {useDispatch, useSelector} from "react-redux";
import {getUserData} from "../redux/userSlice";
import Form from "react-bootstrap/Form";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import SideBarMainLink from "../components/sidebar/sidebarMainLink";

const Orders = () => {
    const { t } = useTranslation();

    const userData = useSelector((state) => state.user.data);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [sortCol, setSortCol] = useState(2);
    const [sortAsc, setSorAsc] = useState(false)
    const [page, setPage] = useState(1)
    const [searchPhrase, setSearchPhrase] = useState('');
    const limit = 50
    let totalCount = 50
    const dispatch = useDispatch()
    const firstCallRef = useRef(true);
    const getOrders = () => {
        setLoading(true)
        axios.get(`/order?order[0][column]=${sortCol}&order[0][dir]=${sortAsc ? 'asc' : 'desc'}${searchPhrase}`, {
            params: {
                page: page
            }
        }).then(response => {
            totalCount = response.data.totalCount
            setData(response.data.items)
            setLoading(false)
            if (firstCallRef.current) {
                dispatch(getUserData())
            }
            firstCallRef.current = false
        })
    }
    useEffect(getOrders, [])
    useDidUpdateEffect(getOrders, [sortCol, sortAsc, page, searchPhrase])

    const handleSort = (sort) => {
        if (sortCol === sort) {
            setSorAsc(state => !state)
        } else {

            setSortCol(sort)
        }
    }
    const FilesMenu = ({ orderId, orderNumber }) => {
const { t } = useTranslation();

        const [show, setShow] = useState(false);
        const target = useRef(null);
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState([]);
        const getData = () => {
            setLoading(true)
            axios.get("/order/" + orderId).then(response => {
                setData(response.data)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
        const ref = useRef(null)
        useOutsideClick(ref, () => {
            setShow(false)
        })

        return (
          <>
            <Dots ref={target} onClick={() => setShow(!show)} />
            {userData?.customerData?.accessToInvoice ? (
              // <SideBarMainLink
              //   title="Faktury"
              //   link="/invoices"
              //   className="link-arrow"
              //   sidebarToggle={sidebarToggle}/>
              <Overlay
                ref={ref}
                target={target.current}
                show={show}
                onEnter={getData}
              >
                <Popover id="popover-basic">
                  <Popover.Header as="h3">Dokumenty do pobrania</Popover.Header>
                  <Popover.Body>
                    {loading ?
                                        <div className={"w-100 h-100 d-flex justify-content-center align-items-center"}>
                                            <Spinner animation={"border"}/>
                                        </div> :
                                        <>
                                            {data.sapOrderNumber ? (
                      <Button
                        variant={"link"}
                        className="d-flex align-items-center my-1 no-underline"
                        onClick={() =>
                          getFile(
                            {
                              docNumber: data.sapOrderNumber,
                              docType: "VBAK",
                            },
                            `${t("Orders.proform")} ${orderNumber}`
                          )
                        }
                      >
                        <Download className="icon-action svg-40 me-1" />
                        {t("Orders.proform")}
                      </Button>
                    ) : null}
                    {data.invoiceNumber ? (
                      <Button
                        variant={"link"}
                        className="d-flex align-items-center my-1 no-underline"
                        onClick={() =>
                          getFile(
                            {
                              docNumber: data.invoiceNumber,
                              docType: "VBRK",
                            },
                            `${t("Pop.invoice_vat")} ${orderNumber}`
                          )
                        }
                      >
                        <Download className="icon-action svg-40 me-1" />
                        {t("Orders.vat")}
                      </Button>
                    ) : null}

                    {data.deliveryNumber ? (
                      <Button
                        variant={"link"}
                        className="d-flex align-items-center my-1 no-underline"
                        onClick={() =>
                          getFile(
                            {
                              docNumber: data.deliveryNumber,
                              docType: "LIKP",
                            },
                            `${t("Pop.wz")} ${orderNumber}`
                          )
                        }
                                  >
                        <Download className="icon-action svg-40 me-1" />
                        {t("Orders.wz")}
                      </Button>
                    ) : null}
                                        </>}
                  </Popover.Body>
                </Popover>
              </Overlay>
            ) : (
              <></>
            )}
          </>
        );
    }


    const columns = [

        {
            title: t("Orders.order_number"),
            dataKey: "sapOrderNumber",
            width: "15%",
            sort: () => (
                <Sort
                    style={{cursor: "pointer"}}
                    onClick={() => handleSort(0)}
                />
            )
        },
        {
            title: t("Orders.order_number_client"),
            dataKey: "clientOrderNumber",
            width: "15%",
            sort: () => (
                <Sort
                    style={{cursor: "pointer"}}
                    onClick={() => handleSort(1)}
                />
            )
        },
        {
            title: t("Orders.order_date"),
            dataKey: "createdOrderDate",
            width: "15%",
            sort: () => (
                <Sort
                    style={{cursor: "pointer"}}
                    onClick={() => handleSort(2)}
                />
            )
        },
        {
            title: t("Orders.net_value"),
            dataKey: "netWholeOrderValue",
            width: "15%",
            render: (value) => <>
                {
                    currencyFormatter(value, userData?.customerData?.canSeeProductPrice)
                }
            </>,
            sort: () => (
                <Sort
                    style={{cursor: "pointer"}}
                    onClick={() => handleSort(3)}
                />
            )
        },
        {
            title: t("Orders.stat"),
            dataKey: "orderStatus",
            width: "15%",
            sort: () => (
                <Sort
                    style={{cursor: "pointer"}}
                    onClick={() => handleSort(4)}
                />
            ), render: (value, item) => <>
                {item[`status${value}Name`] ?? ""}
            </>
        },
        {
            title: t("Orders.delivery_date"),
            dataKey: "orderDeliveryDate",
            width: "15%",
            sort: () => (
                <Sort
                    style={{cursor: "pointer"}}
                    onClick={() => handleSort(5)}
                />
            )
        },
        {
            title: "",
            dataKey: "sapOrderNumber",
            width: "10%",
            render: (value, order) => (

                <div className={"w-100 d-flex mt-3 mt-lg-0"}>
                    <div className={userData?.customerData?.accessToInvoice ? "d-flex justify-content-center w-75" :
                        "d-flex justify-content-center w-100"}
                    >
                        <Link
                            to={"/orders/" + (value === "" ? `EC0/${order.id}` : value)}
                        >
                            <Eye/>

                        </Link>

                    </div>
                    {userData?.customerData?.accessToInvoice ? (
                        <div className={"p-0 w-25"}>
                            <FilesMenu
                                orderId={order.id}
                                orderNumber={order.clientOrderNumber ? order.clientOrderNumber : order.sapOrderNumber}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>


            )
        }

    ];
    const debounceTimeout = 500;
    const debounceSearch = React.useMemo(() => {
        const loadOptions = (event) => {
            const value = event.target.value;
            setPage(1)

            if (value !== "") {
                setSearchPhrase(`&filters[phrase][]=${value}`);
            } else {
                setSearchPhrase("");
            }

        };

        return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout]);

    return (
      <>
        <HeaderBar
          header={t("Orders.orders")}
          headerBarID="cashBox"
          className="ps-34"
        >
          <Form className="d-flex ms-4 position-relative search-input-mobile">
            <Form.Control
              type="search"
              placeholder={t("Pop.search")}
              className="me-2 pill search ps-5"
              aria-label="Search"
              onChange={debounceSearch}
            />
            <Button variant="link" className="btn-search ps-3">
              {" "}
              <img src="/img/lupa.svg" alt="" />
            </Button>
          </Form>
        </HeaderBar>
        <Row>
          <Col className="gray-bg custom-scroll content-container with-header-bar">
            <PerfectScrollbar>
              <div className={"shadow-radius-white p-3"}>
                <CustomTable columns={columns} data={data} loading={loading} />
              </div>
              <PaginationComponent
                setPage={setPage}
                page={page}
                totalCount={totalCount}
                limit={limit}
              />
            </PerfectScrollbar>
          </Col>
        </Row>
      </>
    );
};
export default Orders;
