import {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const CustomTable = ({columns = [], data = [], loading = false, className, summary}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWindowWidth(window.innerWidth);
    }
const {t} = useTranslation()
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        window.addEventListener("load", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
            window.addEventListener("load", handleWindowSizeChange);
        };
    }, [windowWidth]);

    const isMobile = windowWidth <= 992;
    return (
        <>

            <div className={"custom-table orders-tabel position-relative " + className ?? ""}>
                <div className="equal-cols ">
                    {isMobile ? null : (
                        <div className="custom-table-row text-center">
                            {columns?.map((item, index) => {
                                return item.title ? (
                                    <div
                                        className="custom-table-col"
                                        key={index}
                                        style={{
                                            padding: "10px",
                                            width: item.width && item.width,
                                            flexGrow: item.width ? null : "1",
                                        }}
                                    >
                                        {item.sort ?
                                            item.sort()
                                            : null}
                                        {item.title}
                                    </div>
                                ) : null;
                            })}
                        </div>
                    )}
                    {loading ?
                        <div className="w-100 text-center border border-dark rounded-bottom p-5">

                            <Spinner animation={"border"} className={"rounded-circle"}/>
                        </div>

                        : data.length ? data?.map((row, index) => (
                            <div className="custom-table-row text-center" key={index}>
                                {columns?.map((col, i) => {
                                    const value = row[col?.dataKey];
                                    // const mobileTitle = row[col?.mobileTitle];
                                    return col.render ? (
                                        <div
                                            className="custom-cell"
                                            key={i}
                                            style={{
                                                width: isMobile ? "100%" : col.width && col.width,
                                                flexGrow: col.width ? null : "1",
                                            }}
                                        >
                                            {
                                                col.title && isMobile ?
                                                    <div className="fw-bold">{col.title}:</div>
                                                    : null
                                            }
                                            {col.render(value, row, index)}
                                        </div>
                                    ) : (
                                        <div
                                            className="custom-cell"
                                            key={i}
                                            style={{
                                                width: isMobile ? "100%" :  col.width && col.width,
                                                flexGrow: col.width ? null : "1",
                                            }}
                                        >
                                            {isMobile ? <>
                                                <div className="fw-bold">{col.title}:</div>
                                                <div>{value ?? "-"}</div>
                                            </> : value ?? "-"}
                                        </div>
                                    );
                                })}
                            </div>
                        )) : <div className={"w-100 text-center border border-dark rounded-bottom p-5"}>
                            {t("Others.lack")}
                        </div>}

                </div>
                {
                    summary ?
                        <div
                            className={`d-flex justify-content-${isMobile ? "center" : summary.justify ?? "end"}`}
                            style={{width: isMobile ? "75%" : "100%"}}

                        >
                            <div
                                className="summary text-center"
                                style={{width: isMobile ? "100%" :summary.width ?? "100%"}}
                            >
                                {summary.content}
                            </div>
                        </div> : null
                }


            </div>
            {/* </div> */}
            {/* </PerfectScrollbar>
        </Col>
      </Row> */}
        </>
    );
};
export default CustomTable;
