import {currencyFormatter} from "../../helpers/currencyTools";
import i18next from "i18next";

const handleOrderXlsx = (data, canSeePrices) => {
  const { t } = i18next;
  return [
    {
      data: [
        [t("Orders.order_date"), data?.createdOrderDate],
        [
          t("Orders.order_value"),
          `${currencyFormatter(data?.netOrderValue, canSeePrices)} ${
              data.currency
          }`,
        ],
        [
          t("Xlsx.value"),
              `${currencyFormatter(
                  data.netOrderValue - data.netShipCost,
                  canSeePrices
              )} ${data.currency}`,
        ],
        [
          t("Orders.cost"),
          `${currencyFormatter(data?.netShipCost, canSeePrices)} ${
              data.currency
          }`,
        ],
        [
          t("Orders.tax"),
          `${currencyFormatter(data.taxOrderValue, canSeePrices)} ${
              data.currency
          }`,
        ],
        [
          t("Orders.payment"),
          `${currencyFormatter(data.grossOrderValue, canSeePrices)} ${
              data.currency
          }`,
        ],
        [],
        [t("Xlsx.product"), "EAN", t("Xlsx.net_price"), t("Xlsx.quantity"), t("Xlsx.unit"), t("Xlsx.net_total")],
        ...data.orderProducts?.map((product) => [
          product.product.name,
          product.product.ean,
          product.orderOrderProduct.netUnitPrice,
          product.orderOrderProduct.quantity,
          product.orderOrderProduct.unit,
          product.orderOrderProduct.netPriceValue,
        ]),
      ],
      sheetName: t("Xlsx.order_summary"),
    },
  ]
};
export default handleOrderXlsx;
