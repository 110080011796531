import React from "react";
import {Link} from "react-router-dom";
const Footer = () => {
    // const navigate = useNavigate();

    return (
        <div className="footer">
            {
                window.location.host === 'b2bewifoam.de' ?
                    <div className="copyright fw-bold">ewifoam E. Wicklein GmbH</div>
                    :
                    <div className="copyright fw-bold">&#169; DECORA S.A.</div>

            }
            <div><Link className="me-3">Terms of service</Link><Link>Privacy Policy</Link></div>
        </div>

    );
};
export default Footer;
