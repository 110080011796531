import React, {useEffect, useState} from "react";
import {Col, InputGroup, Row, Spinner} from "react-bootstrap";
import HeaderBar from "../components/headerBar/headerBar";
import PaginationComponent from "../components/pagination";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import TableRow from "../components/marketingTable/tableRow";
import {useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ProductSort from "../components/productSort";
import ProductsFilters from "../components/headerBar/filter/productsFilters";
import EanModal from "../components/listMarketing/eanModal";
import {hideCartAlert} from "../redux/cartSlice";
import MarketingListItem from "../components/listMarketing/marketingListItem";
import TableSearch from "../components/marketingTable/tableSearch";
import Form from "react-bootstrap/Form";
import {debounce} from "lodash";
import {ReactComponent as Close} from "../svg/close.svg";
import {toggleView} from "../redux/marketingListSlice";
import {useTranslation} from "react-i18next";


const MarketingList = () => {
    const defaultSearchParams = new URLSearchParams
    defaultSearchParams.append("page", "1")
    let [searchParams, setSearchParams] = useSearchParams(defaultSearchParams);
    const {category, subcategory} = useParams();
    const dispatch = useDispatch();
    const {view} = useSelector((state) => state.marketingList);
    const [loading, setLoading] = useState(false);
    const [priceLoading, setPriceLoading] = useState(false);
    const [limit, setLimit] = useState(20);
    const [totalCount, setTotalCount] = useState(0);
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState([]);
    const {t} = useTranslation();


    const [columnFilters, setColumnFilters] = useState();
    const userData = useSelector((state) => state.user.data);
    const getProducts = () => {
        setLoading(true);
        setProducts([]);
        axios
            .get(
                "/product?" + searchParams,
                {
                    params: {
                        limit: limit,
                        filters: {
                            [subcategory ? "subCategory" : "category"]: {
                                "[]": subcategory ? subcategory : category,
                            },
                            ...columnFilters,
                        },

                    },
                }
            )
            .then((response) => {
                setProducts(response.data.items);
                setLoading(false);
                const getPrices = userData?.customerData.canSeeProductPrice ?? true
                if (getPrices) {
                    setPriceLoading(true)

                    axios.post("/cash-register/price", JSON.stringify(response.data.dataToGetPrice)).then(res => {

                        setProducts(state => {
                            const newState = state.map(item => {
                                const newPrice = res.data.items.find(element => element.productSapIndex === item.sapIndex)

                                if (newPrice === undefined) {
                                    return item
                                }
                                return {
                                    ...item,
                                    priceList: {
                                        ...item.priceList,
                                        maxPrice: newPrice.mainUnitPriceNet,
                                        minPrice: newPrice.mainUnitPriceNet,
                                        conditionUnit: newPrice.unit,
                                    },
                                }
                            })
                            return newState
                        })
                        setPriceLoading(false)

                    })

                }

                // setSearchParams(sortVal + "&" + filters?.join("&") + "&" + searchPhrase)

            })
            .catch(() => {
                setLoading(false);

            });

    };
    useEffect(() => {
        console.log(products)
    }, [products]);
    const getTotalCount = () => {
        axios.get("/product/count?" + searchParams, {
            params: {
                filters: {
                    [subcategory ? "subCategory" : "category"]: {
                        "[]": subcategory ? subcategory : category,
                    },
                    ...columnFilters,
                },
            }
        }).then(({data}) => setTotalCount(data.totalCount))
    }


    useEffect(() => {
        getCategories();
        getProducts();
        getTotalCount();

    }, []);
    useEffect(() => {
        if (view && columnFilters) {
            setColumnFilters(null);
            getProducts();
        }
    }, [view]);
    const [categories, setCategories] = useState([]);
    const [title, setTitle] = useState("");
    const getCategories = () => {
        axios
            .get("/category")
            .then((response) => {
                setCategories(response.data.items);
            })

    };
    useEffect(() => {
        if (subcategory) {
            const find = categories
                .find((item) => item.id === parseInt(category))
                ?.subCategories.find((item) => item.id === parseInt(subcategory));
            setTitle(find?.name);
        } else {
            const find = categories.find((item) => item.id === parseInt(category));
            setTitle(find?.name);
        }
    }, [category, subcategory, categories]);

    const showCartAlert = useSelector((state) => state.cart.showCartAlert);
    const cartAlertMessage = useSelector((state) => state.cart.cartAlertMessage);

    useEffect(() => {
        if (showCartAlert) {
            setTimeout(() => {
                dispatch(hideCartAlert());
            }, 5000);
        }
    }, [showCartAlert]);

    const debounceTimeout = 800;
    const debounceSearch = React.useMemo(() => {
        const loadOptions = (event) => {
            const value = event.target.value;

            if (value !== "") {
                setSearchParams(state => {
                    state.set("filters[searchPhrase][]", value)
                    state.set("page", 1)
                    return state
                })
            } else {
                setSearchParams(state => state.delete("filters[searchPhrase][]"))

            }
        };

        return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        window.addEventListener("load", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
            window.addEventListener("load", handleWindowSizeChange);
        };
    }, []);
    // wider mobile point than standard
    const isMobile = windowWidth <= 1200;

    const initSearch = {
        brandName: "",
        serieName: "",
        searchPhrase: "",
        colorNumberName: "",
        productEanName: "",
    };
    const [searchValues, setSearchValues] = useState(initSearch);


    return (
        <>
            <HeaderBar header={title} className="full-header">
                <div className="d-flex align-items-center">
                    <InputGroup
                        className="me-2 pill search me-3 w-224 search-input-mobile"
                        size={"sm"}
                    >
                        <InputGroup.Text>
                            <img src="/img/lupa.svg" alt="search icon"/>
                        </InputGroup.Text>

                        <Form.Control
                            type="text"
                            placeholder={t("Pop.search")}
                            className="me-2 pill w-100 search ps-5  "
                            aria-label="Search"
                            onChange={debounceSearch}
                            defaultValue={searchParams.get("filters[searchPhrase][]")}
                            autoFocus={searchParams.get("filters[searchPhrase][]")?.length}

                        />
                    </InputGroup>
                    <ProductSort
                        className=" fz-10"
                    />
                    {
                        searchParams.has("filters[productEan][]") ? null :
                            <ProductsFilters/>

                    }

                    {isMobile ? (
                        ""
                    ) : (
                        <>
                            <EanModal/>
                            <Button
                                variant="link"
                                className="icon-action rounded"
                                onClick={() => {
                                    dispatch(toggleView(!view));
                                }}
                            >
                                <img src="/img/menu.svg" alt="" className="ms-3 "/>
                            </Button>
                        </>
                    )}
                </div>
            </HeaderBar>
            <Row>
                <Col
                    className="my-auto main-content-wrapper gray-bg position-relative marketing-list custom-scroll product-list">
                    <PerfectScrollbar>
                        {showCartAlert ? (
                            <div className="marketing-list-success-bar w-100">
                                <img src="/img/done-green.svg" alt=""/> {cartAlertMessage}
                            </div>
                        ) : null}
                        {loading ? (
                            <div
                                className={
                                    "w-100 d-flex justify-content-center align-items-center"
                                }
                            >
                                <Spinner animation={"border"}/>
                            </div>
                        ) : (
                            <>
                                <Row>
                                    <Col>
                                        {!loading &&
                                        products?.length === 0 ?
                                            (filters?.length >= 1 || searchParams.get("filters[searchPhrase][]")?.length >= 1) ?
                                                (<p>{t("Reclamations.lack")} </p>) :
                                                <p>{t("Others.lack")} </p>
                                            : null}
                                        {view ? (
                                            products.map((item, index) => (
                                                <MarketingListItem
                                                    key={index}
                                                    priceLoading={priceLoading}
                                                    item={item}
                                                />
                                            ))
                                        ) : products?.length > 0 ? (
                                            <div className="table-container p-3 bg-white box-shadow-6 border-20">
                                                <Table bordered className="text-align-center product">
                                                    <thead>
                                                    <tr height="60">
                                                        <th>
                                                            {t("ProductSpec.name")}{" "}
                                                            <TableSearch

                                                                filterKey={"searchPhrase"}
                                                            />
                                                        </th>
                                                        <th>
                                                            {t("ProductSpec.brand")}
                                                            <TableSearch

                                                                filterKey={"brandName"}

                                                            />
                                                        </th>
                                                        <th>
                                                            {t("ProductSpec.series")}{" "}
                                                            <TableSearch

                                                                filterKey={"serieName"}
                                                            />
                                                        </th>

                                                        <th>
                                                            {t("ProductSpec.color_key")}
                                                            <TableSearch

                                                                filterKey={"colorNumberName"}
                                                            />
                                                        </th>
                                                        <th>
                                                            EAN
                                                            <TableSearch

                                                                filterKey={"productEanName"}
                                                            />
                                                        </th>
                                                        <th>
                                                            {t("SummaryTable.net")}
                                                            <div>
                                                                <Button
                                                                    variant="link"
                                                                    className="p-0 unstyled-a no-hover table-filter-close"
                                                                    onClick={() => {
                                                                        setColumnFilters(null);
                                                                        setSearchValues(initSearch);
                                                                        getProducts();
                                                                    }}
                                                                >
                                                                    <Close/>
                                                                    {t("ProductSpec.clean_filters")}
                                                                </Button>{" "}
                                                            </div>
                                                        </th>
                                                        <th>{t("Reclamations.count")}</th>
                                                        <th>{t("Reclamations.unit")}</th>
                                                        <th>{t("Reclamations.availability")}</th>
                                                        <th>{t("Reclamations.add")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {products.map((item, index) => (
                                                        <TableRow
                                                            key={index}
                                                            item={item}
                                                            priceLoading={priceLoading}

                                                            collection="Amaron Herringbone"
                                                            buttonText={t("Reclamations.add")}
                                                            netto=""
                                                            productSumValue={
                                                                userData.customerData.canSeeProductPrice
                                                                    ? `145 ${t("Pop.currency")}`
                                                                    : ""
                                                            }
                                                        />
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        ) : null}
                                    </Col>
                                </Row>

                                <PaginationComponent
                                    setPage={p => {
                                        setSearchParams(state => {
                                            state.set("page", p)
                                            return state
                                        })
                                    }}
                                    page={parseInt(searchParams.get("page"))}
                                    totalCount={totalCount}
                                    limit={limit}
                                />
                            </>
                        )}
                    </PerfectScrollbar>
                </Col>
            </Row>
        </>
    );
};
export default MarketingList;
