import Button from "react-bootstrap/Button";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import React, {useState} from "react";

export default function ({item, getData}) {
    const [deleting, setDeleting] = useState(false)

    return deleting ? <Spinner className={"me-3"} animation={'border'} size={'sm'}/> : <Button variant="link" className="rounded icon-action" onClick={() => {
        setDeleting(true)
        axios.delete(`/order/not-delivery/${item.orderProductId}`).then((r) => {
            setDeleting(false)
            getData()
        })
    }}>
        {" "}


                <img src="/img/delete-red.svg" alt=""/>


    </Button>
}