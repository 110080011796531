import {Col, Row, Spinner} from "react-bootstrap";
import axios from "axios";
import {React, useEffect, useState} from "react";
import MarketingProduct from "./marketingProduct";
import {useTranslation} from "react-i18next";


const AdditionalItem = (props) => {
    const [isActive, setActive] = useState(false);
    const {level} = props
    const toggleClass = () => {
        setActive(!isActive);
    };
    const {t} = useTranslation()
    return (
        <>
            <Col xs={12}>
                <Row className="product-wrapper flex-column  justify-content-between product ">

                    <Col className="d-flex flex-column"
                    >
                        <MarketingProduct
                            subProduct={true}

                            image="/img/floor.png"
                            availability="na zamówienie"
                            item={props.item}
                            minOrder="1 opakowanie / 2,16m"
                            quantity="2"
                            discount="-30%"
                            oldPrice="300 €"
                            productSumValue="433,99 €"
                            buttonText="Do koszyka"
                            colorID=""
                            // subList={subList}
                        />
                    </Col>
                    <Col className="accesories-button-col">
                        <Row className="accesories-button-wrapper">
                            {props.item.isDedicatedProducts && level < 2 ? (
                                <div className="text-center fw-bold fz-10" onClick={toggleClass}>
                                <span
                                    className={`arrow-left uppercase rotate show-accesories ${
                                        isActive ? "opened" : "closed"
                                    }`}
                                >
                                  {isActive ? t("List.collapse") : t("List.expand")}
                                </span>
                                </div>
                            ) : null}
                        </Row>
                    </Col>
                </Row>
            </Col>
            {isActive ? <AdditionalProducts level={2} itemID={props.item.id}/> : null}
        </>
    );
}
const AdditionalProducts = ({itemID, level = 1}) => {


    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    const getProducts = () => {
        setLoading(true)
        axios.get("/product/dedicated/" + itemID).then((response) => {

            setProducts(response.data.items)
            setLoading(false)
        })
    }
    useEffect(() => {
        getProducts()
    }, [])


    return (
        <Row className={`list-accesories ms-auto ${level === 2 ? "second-level-accesories " : ""}`}>
            {
                loading ?
                    <Spinner animation={"border"} className="mb-3"/> :
                    products?.sort((a, b) => {
                        return  b.category.id - a.category.id
                    }).map((item, index) => (


                        <AdditionalItem level={level} item={item} key={index}/>


                    ))
            }

        </Row>
    )
}
export default AdditionalProducts   