import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import ProductSort from "../productSort";
import ProductsFilters from "./filter/productsFilters";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";


function HeaderBar({header, children, className}) {
    // const [show, setShow] = useState(true);
    // const [lastScrollY, setLastScrollY] = useState(0);
    //
    // const controlNavbar = () => {
    //
    //     if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
    //         setShow(false);
    //     } else { // if scroll up show the navbar
    //         setShow(true);
    //     }
    //     console.log(lastScrollY)
    //     // remember current page location to use in the next move
    //     setLastScrollY(window.scrollY);
    //
    // };
    //
    // useEffect(() => {
    //     window.addEventListener('scroll', controlNavbar);
    //
    //     // cleanup function
    //     return () => {
    //         window.removeEventListener('scroll', controlNavbar);
    //     };
    //
    // }, [lastScrollY]);
    return (
        <div
            className={`d-flex justify-content-between header-bar align-items-center position-relative navbar-lower-padding ${className}`}>
            <div>
                <h1>{header}</h1>
            </div>
            {children}
        </div>
    );
}

export default HeaderBar;
