import Pagination from "react-bootstrap/Pagination";
import {Row} from "react-bootstrap";
import {useEffect, useState} from "react";

function PaginationComponent({page, setPage, limit, totalCount}) {


    let [items, setItems] = useState([]);


    useEffect(() => {
        let a = []
        const x = Math.ceil(totalCount / limit)


        for (let number = 1; number <= x; number++) {
            a.push(
                {
                    page: number
                }
            );
        }
        setItems(a)
    }, [totalCount, page])

    return (
        <>
            {
                items.length > 1 ?
                    <Pagination
                        className="justify-content-center"
                    >

                        { page > 1 ? <Pagination.Prev onClick={() => setPage(page - 1)}/> : null}
                        {page >= 4 ? <Pagination.Item key={1} active={1 === page} onClick={() => setPage(1)}>
                            {1}
                        </Pagination.Item> : null}
                        {
                            items.length >= 10 && page >= 5 ?
                                <Pagination.Ellipsis/> : null
                        }
                        {
                            items.filter(item =>
                                (item.page >= page - 2 && item.page < page) ||
                                (item.page <= page + 2 && item.page > page) ||
                                item.page === page
                            ).map(item => (
                                <Pagination.Item key={item.page} active={item.page === page}
                                                 onClick={() => setPage(item.page)}>
                                    {item.page}
                                </Pagination.Item>
                            ))
                        }
                        {
                            items.length >= 10 && items.length - 4 >= page ?
                                <Pagination.Ellipsis/> : null
                        }
                        {page <= items.length - 3 ?
                            <Pagination.Item key={items.length} active={items.length === page}
                                             onClick={() => setPage(items.length)}>
                                {items.length}
                            </Pagination.Item>
                            : null}
                        {page < items.length ? <Pagination.Next onClick={() => setPage(page + 1)}/> : null}

                    </Pagination> :
                    null
            }
        </>


    )
        ;
}

export default PaginationComponent;
