import {useEffect, useState} from "react";

export default function useIsReady(quantity, extraQuantity, data) {
    const numberOfProduct = parseInt(data?.numberOfProduct);
    const promotionProducts = parseInt(data?.promotionDetails?.numberOfProduct);
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        switch (data?.promotionType) {
            case "first":
                setIsReady(quantity === numberOfProduct && extraQuantity === promotionProducts)
                break;
            default:
                setIsReady(quantity === numberOfProduct)
        }

    }, [quantity, extraQuantity])
    return isReady

}