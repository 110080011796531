import {Col, Row} from "react-bootstrap";
import HeaderBar from "../components/headerBar/headerBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const ClientProfile = () => {
    const userData = useSelector((state) => state.user.data);
const { t } = useTranslation();  

    return (
      <>
        <HeaderBar
          header={t("Client.profile")}
          headerBarID="client-profile"
          className="header-page-1386"
        ></HeaderBar>
        <Row>
          <Col className="gray-bg custom-scroll content-container with-header-bar ">
            <PerfectScrollbar>
              <Row className="max-1386 pt-4">
                <Col md={3}>
                  <div className={"mb-3"}>{t("Client.data")}</div>

                  <div className="shadow-radius-white p-30 client-card ">
                    <div className="fz-12">
                      <div className="fw-bold">
                        {userData?.customerData.payer.fullName}
                      </div>
                      <div>{userData?.customerData.payer.companyName}</div>
                      <div>{userData?.customerData.payer.street}</div>
                      <div>
                        {userData?.customerData.payer.postCode}{" "}
                        {userData?.customerData.payer.city}
                      </div>
                      <div>
                        {userData?.customerData.recipient.country == "PL"
                          ? t("Others.poland")
                          : userData?.customerData.recipient.country}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className={"mb-3"}>{t("Client.recipient")}</div>

                  <div className="shadow-radius-white p-30 client-card ">
                    <div className="fz-12">
                      <div className="fw-bold">
                        {userData?.customerData.payer.fullName}
                      </div>
                      <div>{userData?.customerData.recipient.companyName}</div>
                      <div>{userData?.customerData.recipient.street}</div>
                      <div>
                        {userData?.customerData.recipient.postCode}{" "}
                        {userData?.customerData.recipient.city}
                      </div>
                      <div>
                        {userData?.customerData.recipient.country == "PL"
                          ? t("Others.poland")
                          : userData?.customerData.recipient.country}
                      </div>
                    </div>
                  </div>
                </Col>
                {userData?.customerData.extraEmail ? (
                  <Col md={3}>
                    <div className={"mb-3"}>{t("Client.contact")}</div>

                    <div className="shadow-radius-white p-30 client-card ">
                      <div className="fz-12">
                        <div className="fw-bold mt-3">{t("Client.mail")}</div>
                        <div>{userData?.customerData.extraEmail}</div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
                <Col md={3}>
                  <div className={"mb-3"}>{t("Client.additional")}</div>

                  <div className="shadow-radius-white p-30 client-card ">
                    <div className="fz-12">
                      <div className="fw-bold">{t("Client.worker")}</div>
                      <div className="fw-bold mt-3">
                        {userData?.customerData.canAdminCreateOrderForClient
                          ? t("Others.yes")
                          : t("Others.no")}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row  className="max-1386  mt-4">
                <Col md={3}>
                  <div>{t("Client.adresses")}</div>

                  <div className="shadow-radius-white p-30 client-card mt-3 ">
                    <div className="fz-12">
                      <div className="fw-bold">
                        {userData?.customerData.payer.fullName}
                      </div>
                      <div>{userData?.customerData.recipient.companyName}</div>
                      <div>{userData?.customerData.recipient.street}</div>
                      <div>
                        {userData?.customerData.recipient.postCode}{" "}
                        {userData?.customerData.recipient.city}
                      </div>
                      <div>
                        {userData?.customerData.recipient.country == "PL"
                          ? t("Others.poland")
                          : userData?.customerData.recipient.country}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              {userData?.customerData.canSeeCreditLimit ? (
                <Row className="max-1386 mt-4">
                  <Col md={3}>
                    <div>{t("Client.limits")}</div>

                    <div className="shadow-radius-white p-30 client-card mt-3 ">
                      <div className="fz-12">
                        <div className="fw-bold">{t("Client.max")}</div>
                        <div className={"fz-32 mt-3"}>
                          {userData?.customerLimit}
                          {t("Pop.currency")}
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className={"pt-4"}></div>

                    <div className="shadow-radius-white p-30 client-card mt-3 ">
                      <div className="fz-12">
                        <div className="fw-bold">{t("Client.saldo")}</div>
                        <div className={"fz-32 mt-3"}>
                          {userData?.saldo}
                          {t("Pop.currency")}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className={"pt-4"}></div>

                    <div className="shadow-radius-white p-30 client-card mt-3 ">
                      <div className="fz-12">
                        <div className="fw-bold">{t("Client.debt")}</div>
                        <div className={"fz-32 mt-3"}>
                          {userData?.debtsDue}
                          {t("Pop.currency")}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className={"pt-4"}></div>

                    <div className="shadow-radius-white p-30 client-card mt-3 ">
                      <div className="fz-12">
                        <div className="fw-bold">{t("Client.deadline")}</div>

                        <div className={"fz-32 mt-3"}>
                          {userData?.paymentTerm}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className={"max-1386 pb-5 mt-4"}>
                {userData?.customerData.account ? (
                  <Col md={3}>
                    <div>{t("Client.contact")}</div>
                    <div className="shadow-radius-white p-30 client-card mt-3 ">
                      <div className="fz-12">
                        <div className="fw-bold">
                          {t("Client.commercial_supervisor")}
                        </div>
                        <div>{userData?.customerData.account.name2}</div>
                        {userData?.customerData.account.email ? (
                          <div>{userData?.customerData.account.email}</div>
                        ) : (
                          <></>
                        )}
                        <div>{userData?.customerData.account.phone}</div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
                {userData?.customerData.support ? (
                  <Col md={3}>
                    <div className={"pt-4"}>
                      {console.log(userData.customerData)}
                    </div>
                    <div className="shadow-radius-white p-30 client-card mt-3 ">
                      <div className="fz-12">
                        <div className="fw-bold">
                          {t("Client.account_manager")}
                        </div>
                        <div>{userData?.customerData.support.name2}</div>
                        {userData?.customerData.support.email ? (
                          <div>{userData?.customerData.support.email}</div>
                        ) : (
                          <></>
                        )}
                        <div>{userData?.customerData.support.phone}</div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}

                {/*<Col md={3} >*/}
                {/*    <div className={"pt-4"}>*/}

                {/*    </div>*/}

                {/*    <div className="shadow-radius-white p-30 client-card mt-3 ">*/}
                {/*        <div className="fz-12">*/}
                {/*            <div className="fw-bold">Opiekun DOK</div>*/}
                {/*            <div>Jan Kowalski</div>*/}
                {/*            <div>jan@decora.pl</div>*/}
                {/*            <div>605-203-102</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Col>*/}
              </Row>
            </PerfectScrollbar>
          </Col>
        </Row>
      </>
    );
};
export default ClientProfile;
