import {createSlice} from "@reduxjs/toolkit";

export const toastSlice = createSlice({
    name: "toast",
    initialState: {
        show: false,
        variant: "light",
        message: "",
        header: "",
        delay: 3000
    },
    reducers: {
        setToast: (state, {payload}) => {
            state.variant = payload.variant ?? 'light'
            state.message = payload.message ?? ''
            state.header = payload.header ?? ''
            state.delay = payload.delay ?? 3000
            state.show = payload.show ?? true
        }
    }
})

export const {setToast} = toastSlice.actions

export default toastSlice.reducer