import {React, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Badge, Col, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import PriceInPill from "../product/priceInPill";
import Counter from "../product/counter";
import UnitSelect from "../product/unitSelect";
import AddToCart from "../product/btnAddToCart";
import priceAndUnit from "../../helpers/priceAndUnit";
import axios from "axios";
import checkAvailability from "../../helpers/checkAvailability";
import CustomSelect from "../customSelect/customSelect";
import Button from "react-bootstrap/Button";
import {currencyFormatter} from "../../helpers/currencyTools";
import usePricePick from "../../customHooks/usePricePick";
import data from "bootstrap/js/src/dom/data";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


function MarketingProduct({

                              discount, subProduct = false, item, priceLoading
                          }) {
    const [quantity, setQuantity] = useState(1);
    const {t} = useTranslation();
    const [showCartBadge, setShowCartBadge] = useState(false);
    const [unit, setUnit] = useState(item.availableUnits ? item.availableUnits[0]?.unit : "");
    const [cartReadyData, setCartReadyData] = useState(null)
    useEffect(() => {
        setCartReadyData(priceAndUnit(item, quantity, unit))
    }, [item, quantity, unit])
    const userData = useSelector((state) => state.user.data);

    const priceUnitData = item?.availableUnits?.find((element) => element.unit === item.priceList?.conditionUnit);
    const [loadingAvailability, setLoadingAvailability] = useState(false)
    const [availabilities, setAvailabilities] = useState(null)
    const price = item?.priceList?.minPrice

    const handleImageSrc = (images) => {
        let newImages = images
        if (item.category?.id === 4) {
            newImages = [
                ...images.filter(item => item.category === "Packshot"),
                ...images.filter(item => item.category !== "Packshot")
            ]
        }
        const firstImg = newImages[0]

        return `${firstImg?.resized?.list?.default ? firstImg?.resized?.list?.default : firstImg?.default}`
    }

    return (
        <Row className={"position-relative"}>

            {item.isInCart || showCartBadge ?
                <OverlayTrigger
                    overlay={
                        <Tooltip id="button-tooltip">
                            {t("List.cart_badge")}
                        </Tooltip>
                    }
                >
                    <Badge className={"cart-badge"} pill bg={"primary"}>!</Badge>
                </OverlayTrigger>
                : null}

            <Col xs="auto" className="image-col pe-0">
                <div className="relative-padding">
                    <Link className="zoom" to={"/product/" + item.id}>
                        {" "}
                        <img
                            src={handleImageSrc(item.images)}
                            alt=""
                            className="img-fluid"
                        />
                    </Link>
                </div>
                {!subProduct && item.productStatuses
                    ? item.productStatuses?.map((status) => (
                        <div
                            className={`text-uppercase text-center fw-bold fz-10 mt-1`}
                        >
                            {status.name}
                            <div
                                style={{
                                    backgroundColor: status.color,
                                    width: "100%",
                                    height: "5px",
                                }}
                            ></div>
                        </div>
                    ))
                    : null}
            </Col>
            <Col className="product-description ps-3 fz-12">
                <h4 className="fz-12 fw-bold">
                    {item.category?.subCategories
                        ? item.category?.subCategories[0].name
                        : ""}
                </h4>
                <div className="d-flex align-items-start">
                    <div className="mb-1">
                        <Link
                            to={"/product/" + item.id}
                            className="me-3 unstyled-a hover"
                        >
                            <h3 className={"mw-100"}>{item.name}</h3>{" "}
                        </Link>
                        <Badge pill bg="dark" className=" fz-12 fw-bold mt-1">
                            {item.color?.number}
                        </Badge>
                    </div>
                </div>

                <p>
                    <span className="fw-bold">EAN: </span>
                    {item.ean}
                </p>
                <p>
                    <span className="fw-bold">{t("List.brand")}: </span>
                    {item.brand?.name}
                </p>

                <p>
                    <span className="fw-bold">{t("List.min_order")}: </span>
                    {`${priceUnitData?.minOrderQuantity} ${priceUnitData?.unitName}`}
                </p>
            </Col>
            <Col
                xs={12}
                md={6}
                lg={2}
                xl={2}
                xxl="auto"
                className="text-center d-flex flex-column ms-auto price-wrapper align-items-center justify-content-center"
            >
                {userData?.customerData?.canSeeProductPrice ? (
                    <div className="d-flex flex-column justify-content-center">
                        <PriceInPill priceList={item?.priceList} priceLoading={priceLoading}/>
                    </div>
                ) : (
                    <></>
                )}

                <div>
                    {priceUnitData?.unit !== item.availableUnits[0].unit ? (
                        <span className="fz-12">
                1 {priceUnitData?.shortUnitName}
                            {" = "}
                            {priceUnitData?.counter / priceUnitData?.denominator}{" "}
                            {item.availableUnits[0].shortUnitName}
              </span>
                    ) : null}
                </div>
            </Col>
            <Col className="d-flex justify-content-end price-unit-wrapper">

                <div className="d-flex flex-wrap text-center counter-cart-wrapper">
                    <div
                        className={
                            "d-flex flex-column align-items-center counter-wrapper"
                        }
                    >

                        <div className={"gap d-flex"}>
                            <Counter quantity={quantity} setQuantity={setQuantity}/>
                            {/*<UnitSelect*/}
                            {/*    className={"h-100"}*/}
                            {/*    data={item?.availableUnits}*/}
                            {/*    value={unit}*/}
                            {/*    onSelect={(e) => setUnit(e.target.value)}*/}
                            {/*/>*/}
                            <CustomSelect
                                options={item.availableUnits}
                                value={item.priceList.conditionUnit}
                                onChange={setUnit}
                            />
                        </div>
                        <div className="text-center fz-12 mt-2">
                <span>
                  {cartReadyData?.cartQuantity}{" "}{cartReadyData?.defaultUnit?.shortUnitName}
                    {
                        cartReadyData?.defaultUnit?.unit !== cartReadyData?.baseUnit?.unit ?
                            (
                                <>
                                    {" "}
                                    = {cartReadyData?.baseQuantity}{" "}
                                    {cartReadyData?.baseUnit.shortUnitName}
                                </>
                            ) : null
                    }
                </span>
                        </div>
                    </div>
                    <div className="mt-custom-xl-3 add-product ">


                        <AddToCart
                            buttonText={t("ProductPage.basket")}
                            item={item}
                            quantity={cartReadyData?.cartQuantity}
                            setQuantity={(val) => {
                                setQuantity(val);
                            }}
                            onAddingComplete={() => {
                                setShowCartBadge(true)
                            }}
                            selectedUnit={unit}
                            unit={cartReadyData?.defaultUnit?.unit}
                            productSumValue={
                                userData?.customerData.canSeeProductPrice
                                    ? `(${currencyFormatter(
                                        cartReadyData?.cartQuantity * price,
                                        userData?.customerData.canSeeProductPrice
                                    )} ${item?.priceList?.currency})`
                                    : "-"
                            }
                            className="w-224"
                            priceLoading={priceLoading}
                        />

                        <small className={"d-block"}>
                            {
                                priceLoading ? <Spinner animation={'border'} size={'sm'}/> : <>
                                    {t("Pop.unit_price")}: {userData?.customerData.canSeeProductPrice ? `${price} ${item?.priceList?.currency}` : '-'}
                                </>
                            }
                        </small>

                        <div className="fz-12 mt-2 fw-bold">
                            <Button
                                variant={"link"}
                                onClick={() => {
                                    setLoadingAvailability(true);

                                    checkAvailability(
                                        item,
                                        cartReadyData?.baseQuantity,
                                        cartReadyData?.baseUnit?.unit
                                    ).then((response) => {
                                        setAvailabilities(response.data.items[0].availabilities);
                                        setLoadingAvailability(false);
                                    });
                                }}
                            >
                                {t("List.availability")}
                            </Button>
                            <div className={"d-flex flex-column align-items-center"}>
                                {loadingAvailability ? (
                                    <Spinner animation={"border"} size={"sm"}/>
                                ) : availabilities ? (
                                    availabilities?.map((element, index) => (
                                        <div
                                            key={index}
                                            className={`product-availability--${
                                                element.status === "API.CART.AVAILABLE_NOW"
                                                    ? "now"
                                                    : element.status === "API.CART.AVAILABLE_FROM"
                                                        ? "soon"
                                                        : element.status === "API.CART.NOT_AVAILABLE"
                                                            ? "never"
                                                            : ""
                                            }`}
                                        >
                                            {element.statusName}: {element.quantity.toFixed(3)}{" "}
                                            {element.unit}
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>


            </Col>
        </Row>
    );
}

export default MarketingProduct;
