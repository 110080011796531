import CustomTable from "../components/table/customTable";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderBar from "../components/headerBar/headerBar";
import PaginationComponent from "../components/pagination";
import { ReactComponent as Sort } from "../svg/sort-icon.svg";
import useDidUpdateEffect from "../customHooks/useDidUpdateEffect";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../redux/userSlice";
import { useTranslation } from "react-i18next";

const Offers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [sortCol, setSortCol] = useState(3);
  const [sortAsc, setSorAsc] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const firstCallRef = useRef(true);
  const { offerAlert } = useSelector((state) => state.offer);
  const getOffers = () => {
    setLoading(true);
    axios
      .get(
        `/offer?order[0][column]=${sortCol}&order[0][dir]=${
          sortAsc ? "asc" : "desc"
        }`,
        {
          params: {
            page: page,
            limit: 20,
          },
        }
      )
      .then((response) => {
        setTotalCount(response.data.totalCount);
        setOffers(response.data.items);
        setLoading(false);
        if (firstCallRef.current) {
          dispatch(getUserData());
        }
        firstCallRef.current = false;
      });
  };
  useEffect(getOffers, []);
  useDidUpdateEffect(getOffers, [sortCol, sortAsc, page]);

  const [archive, setArchive] = useState();
  const [showArchive, setShowArchive] = useState(false);
  const [loadingArchive, setLoadingArchive] = useState(false);
  const getArchive = () => {
    setLoadingArchive(true);
    axios
      .get("/offer/archive?order[0][column]=3&order[0][dir]=desc")
      .then((response) => {
        setArchive(response.data.items);
        setLoadingArchive(false);
      });
  };
  const handleSort = (sort) => {
    if (sortCol === sort) {
      setSorAsc((state) => !state);
    } else {
      setSortCol(sort);
    }
  };

  const columns = [
    {
      title: t("Offer.number_offer"),
      dataKey: "offerNumber",
      width: "20%",
      render: (value, item) => <Link to={"/offers/" + item.id}>{value}</Link>,
      sort: () => (
        <Sort style={{ cursor: "pointer" }} onClick={() => handleSort(1)} />
      ),
    },
    {
      title: t("Offer.name"),
      dataKey: "offerName",
      width: "20%",
      sort: () => (
        <Sort style={{ cursor: "pointer" }} onClick={() => handleSort(2)} />
      ),
    },
    {
      title: t("Offer.creation_date"),
      dataKey: "createdAt",
      width: "20%",
      sort: () => (
        <Sort style={{ cursor: "pointer" }} onClick={() => handleSort(3)} />
      ),
    },
    {
      title: t("Offer.expiration_date"),
      dataKey: "expiredAt",
      width: "20%",
      sort: () => (
        <Sort style={{ cursor: "pointer" }} onClick={() => handleSort(4)} />
      ),
    },
    {
      title: "",
      dataKey: "id",
      render: (id) => (
        <Button onClick={() => navigate("/offers/" + id)}>{ t("Offer.see")}</Button>
      ),
    },
  ];
  const archiveColumns = [
    {
      title: t("Offer.number"),
      dataKey: "offerNumber",
      width: "16.66%",
      render: (value, item) => <Link to={"/offers/" + item.id}>{value}</Link>,
    },
    {
      title: t("Offer.name"),
      dataKey: "offerName",
      width: "16.66%",
    },
    {
      title: t("Offer.creation_date"),
      dataKey: "createdAt",
      width: "16.66%",
    },
    {
      title: t("Offer.expiration_date"),
      dataKey: "expiredAt",
      width: "16.66%",
    },
    {
      title: t("Offer.status"),
      dataKey: "status",
      width: "16.66%",
    },
    {
      title: "",
      dataKey: "id",
      render: (id) => <Link to={"/offers/" + id}>{t("Offer.see")}</Link>,
    },
  ];
  return (
    <>
      <HeaderBar header={t("Offer.offers")} />

      <Row className={"p-relative"}>
        <Col className=" main-content-wrapper gray-bg  marketing-list custom-scroll ">
          <PerfectScrollbar>
            {offerAlert ? (
              <div className="marketing-list-success-bar w-100">
                <img src="/img/done-green.svg" alt="" /> {t("Offer.accepted")}
              </div>
            ) : null}
            <div className={"shadow-radius-white p-3"}>
              <CustomTable columns={columns} data={offers} loading={loading} />
            </div>

            {offers ? (
              <PaginationComponent
                page={page}
                setPage={setPage}
                limit={20}
                totalCount={totalCount}
              />
            ) : null}
            <Button
              className={"mt-5 mb-3"}
              variant={"dark"}
              onClick={() => {
                setShowArchive((state) => !state);
                getArchive();
              }}
            >
              {showArchive ? t("Offer.hide") : t("Offer.show")}
            </Button>
            {showArchive ? (
              <div className={"shadow-radius-white p-3 mb-5"}>
                <h3>{t("Offer.archive")}</h3>
                <CustomTable
                  columns={archiveColumns}
                  data={archive}
                  loading={loadingArchive}
                />
              </div>
            ) : null}
          </PerfectScrollbar>
        </Col>
      </Row>
    </>
  );
};
export default Offers;
