import {React} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function OfferCounter({className = "", quantity, setQuantity, max}) {

    const handleChange = (e) => {

        // if (e.target.value === '') return
        let val = parseInt(e.target.value)
        if(val <= max) {
            setQuantity(val)
        }


    }
    //increase counter
    const increase = () => {
        if(quantity < max){
            setQuantity(quantity + 1);
        }
    };

    //decrease counter
    const decrease = () => {
        if (quantity >= 1) {
            setQuantity(quantity - 1);
        }
    };

    return (
        <Form.Group className={`counter ${className}`}>
            <Button variant="light" onClick={decrease} className="border-0 p-1">
                <img src="/img/minus.svg" alt=""/>
            </Button>{" "}
            <Form.Control
                value={quantity}
                size="sm"
                max={max}
                className="text-center"
                type={"number"}
                onChange={handleChange}
            />
            <Button variant="light" onClick={increase} className="border-0 p-1">
                <img src="/img/plus.svg" alt=""/>
            </Button>{" "}
        </Form.Group>
    );
}
