import { React, useState } from "react";
import Form from "react-bootstrap/Form";

function FilterElement({ dropdown, children }) {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
      <div
        className={` py-2 px-4 ${
          isActive ? "opened-parent" : "closed-parent"
        }`}
       
      >
        <span className={`arrow-left rotate w-100 d-inline-block ${
          isActive ? "opened" : ""
        }`}  onClick={toggleClass}>{dropdown}</span>
        <div className="not-dot mb-0 py-3 ps-2">{children}</div>
      </div>
  );
}

export default FilterElement;
