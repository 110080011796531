import React, {useEffect, useMemo, useState} from "react";
import {ReactComponent as Chevron} from "../../svg/keyboard_arrow_down_black_24dp.svg";
import {ReactComponent as Delete} from "../../svg/smallDelete.svg";

import {debounce} from "lodash";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const OrdersSelect = ({
                          onSelect,
                          onClear
                      }) => {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)
const { t } = useTranslation();  

    const [searchedPhrase, setSearchedPhrase] = useState('');
    const { orderNumber} = useSelector(state => state.reclamation)



    useEffect(() => {
        if (!selectedOption) return
        onSelect(selectedOption);
    }, [selectedOption]);
    useEffect(() => {
        if(orderNumber){
            setOptions(state=>[...state,
                {
                    sapOrderNumber: orderNumber
                }
            ])
            setSelectedOption(orderNumber)
        }
    }, []);

    const loadOptions = (event) => {
        // setLoading(true);
        // setIsOptionsOpen(true)

        setLoading(true);
        let value = '';
        if(event) {
            setIsOptionsOpen(true)
            value = event.target.value;
            setSearchedPhrase(value);
        } else if(searchedPhrase) {
            setIsOptionsOpen(true)
            value = searchedPhrase;
        }

        axios.get("/complaint/order?filters[complaintPhrase][]=" + value, {
            params: {
                limit: 100,
                page: 1
            }
        }).then(response => {
            setOptions(response.data.items)
            setLoading(false)
        })
    };

    const toggleOptions = () => {
        if(options.length === 0 && !isOptionsOpen) {
            loadOptions();
        }
        setIsOptionsOpen(!isOptionsOpen);
    };

    const setSelectedThenCloseDropdown = (value) => {
        setSelectedOption(value);
        setSearchedPhrase('');
        setIsOptionsOpen(false);
    };

    const handleKeyDown = (index) => (e) => {
        switch (e.key) {
            case " ":
            case "SpaceBar":
            case "Enter":
                e.preventDefault();
                setSelectedThenCloseDropdown(index);
                break;
            default:
                break;
        }
    };
//https://stage.decorab2b.jellydev.pl/api/order?filters[complaintPhrase][]=1200
    //https://stage.decorab2b.jellydev.pl/api/complaint/order
    const debounceTimeout = 800;
    const debounceSearch = useMemo(() => {
        loadOptions();
        return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout]);

    return (
      <div className="search-select mb-3 w-100">
        <div className={"wrapper w-100"}>
          {selectedOption ? (
            <div
              className={
                "d-flex p-2 justify-content-between align-items-center w-100"
              }
            >
              <span>{selectedOption}</span>
              <Delete
                className={"ms-2 cursor-pointer"}
                onClick={() => {
                  setSelectedOption(null);
                  onClear();
                }}
              />
            </div>
          ) : (
            <input
              type="text"
              className={"w-100"}
              placeholder={t("Others.enter")}
              onInput={debounceSearch}
            />
          )}

          <button
            type="button"
            aria-haspopup="listbox"
            aria-expanded={isOptionsOpen}
            onClick={toggleOptions}
          >
            <Chevron
              style={{ transform: `rotate(${isOptionsOpen ? "180" : "0"}deg)` }}
            />
            {/*    <span className={"w-100 text-center"}>*/}
            {/*    {options?.find((item) => item.unit === selectedOption)?.unitName}*/}
            {/*</span>*/}
          </button>
        </div>

        <ul
          className={`options ${isOptionsOpen ? "show" : ""}`}
          role="listbox"
          tabIndex={-1}
        >
          {loading ? (
            <li>
              <Spinner animation={"border"} size={"sm"} />
            </li>
          ) : options.length ? (
            options?.map((option, index) => (
              <li
                key={index}
                id={option.sapOrderNumber}
                role="option"
                aria-selected={selectedOption == option.sapOrderNumber}
                tabIndex={0}
                onKeyDown={handleKeyDown(index + 1)}
                onClick={() => {
                  setSelectedThenCloseDropdown(option.sapOrderNumber);
                }}
              >
                {option.sapOrderNumber}
              </li>
            ))
          ) : (
            <li>{t("Others.lack")}</li>
          )}
        </ul>
      </div>
    );
};

export default OrdersSelect;
