import axios from "axios";
 const checkAvailability = (item, quantity, selectedUnit)=>{

    return axios.post("/product/availability", {
        products: [
            {
                productSapIndex: item.sapIndex,
                quantity: quantity,
                unit: selectedUnit,
                unitPrice: item?.priceList?.minPrice,
                currency: item.priceList?.currency
            }
        ]
    })
}
export default  checkAvailability